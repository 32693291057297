var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0px","width":"100%","border-top":"3px solid #4590a0","border-bottom":"3px solid #4590a0"}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","height":"50"}},[((
                _vm.collectionObj !== null &&
                _vm.selectedInstallmentSchedule !== null &&
                _vm.selectedInstallmentSchedule.loan.repayment_info.payment_method === 'DEBIT ORDER' &&
                _vm.selectedInstallmentSchedule.softy_comp_collections.length > 0
            ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"20px"},attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.openViewScheduleDebitOrderDetailsDialog()}}},on),[_c('v-icon',[_vm._v("mdi-cash-100")]),_vm._v(" View All Debit Orders ")],1)]}}],null,false,3202729687)},[_c('span',[_vm._v("View All Debit Orders")])]):_vm._e()],1),(_vm.collectionObj !== null && _vm.collectionObj !== undefined)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.installment_schedule_headers,"items":_vm.collectionObj.upcoming_loan_schedules.length > 0 ? _vm.transformedSchedules() : [],"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectInstallmentScheduleTabRow},scopedSlots:_vm._u([{key:"item.loan_number",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v("L"+_vm._s(item.loan.loan_number))])]}},{key:"item.deadline_date",fn:function(ref){
            var item = ref.item;
return [(item.deadline_date !== null)?_c('td',[_vm._v(_vm._s(item.deadline_date.split('T')[0]))]):_vm._e()]}},{key:"item.softy_comp_collections",fn:function(ref){
            var item = ref.item;
return [(item.softy_comp_collections !== null)?_c('td',[(item.softy_comp_collections.filter(function (collection) { return collection.local_status === 'ACTIVE'; }).length > 0)?_c('v-chip',{attrs:{"color":"success","dark":""}},[_vm._v(" "+_vm._s(item.softy_comp_collections.filter(function (collection) { return collection.local_status === 'ACTIVE'; }).length)+" ")]):_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(item.softy_comp_collections.filter(function (collection) { return collection.local_status === 'ACTIVE'; }).length)+" ")]),_vm._v(" / "),_c('v-chip',{attrs:{"color":"grey","dark":""}},[_vm._v(" "+_vm._s(item.softy_comp_collections.length)+" ")])],1):_vm._e()]}},{key:"item.age_day",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.age_day)+" DAYS")])]}},{key:"item.loan.premium",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.loan.premium))])]}},{key:"item.collected_amount",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" R "+_vm._s(item.collected_amount)+" ")])]}},{key:"item.expected_amount",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" R "+_vm._s(item.expected_amount)+" ")])]}}],null,false,521056787)}):_vm._e(),(_vm.selectedInstallmentSchedule !== null)?_c('ViewScheduleItemDebitOrderDetailsDialog',{attrs:{"dialog":_vm.open_view_schedule_debit_order_details_dialog,"loan":_vm.selectedInstallmentSchedule.loan,"installment_item":_vm.selectedInstallmentSchedule}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }