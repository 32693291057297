import gql from 'graphql-tag'

export const calculateLoanPricing = gql`
    mutation calculateLoanPricing($input: LoanPricingInput!) {
        calculateLoanPricing(input: $input) {
            initiation_fee
            monthly_admin_fee
            loan_term
            initiation_fee_paid_at_inception
            total_expected_collection

            premium_profile {
                term_start
                term_end
                period

                principal
                initiation_fees
                admin_fees
                interest
                interest_amount
                total
            }
            amortization_schedule {
                installment_number

                premium_principal
                premium_admin_fee
                premium_initiation_fee
                premium_interest
                premium_total

                status
            }
        }
    }
`

export const businessProcessLoanQuote = gql`
    mutation businessProcessLoanQuote($input: LoanQuoteInput!) {
        businessProcessLoanQuote(input: $input) {
            errors
            message
            business_object {
                ... on LoanQuote {
                    _id
                    business_partner {
                        _id
                        name
                    }
                    quote_number
                    entity_monthly_income

                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    premium
                    agent_score
                    scheme {
                        _id
                        name
                        scheme_number
                    }
                    entity {
                        _id
                        name
                        surname
                        id_number

                        bank_account_details {
                            _id
                            purpose
                            bank {
                                _id
                                name
                                universal_branch_code
                            }
                            account_type
                            account_number

                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                            deleted
                            delete_date
                            deleted_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            delete_reason
                        }
                    }
                    # collection_banking
                    # payout_banking
                    banking_details_id
                    bank_name
                    bank_account_number

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    status
                    bank_statement_status
                    agent_status
                    decline_reason

                    pricing_input {
                        loan_type

                        principal_amount
                        loan_term_units
                        loan_term

                        specialized_landing

                        fees_config {
                            monthly_admin_fee
                            initiation_fee_principal_percentage
                            initiation_fee_paid_at_inception
                        }
                        premium_allocation_priority {
                            principal
                            interest
                            admin_fee_premium
                            initiation_fee_premium
                        }
                        interest_config {
                            annual_interest_rate
                            annual_interest_rate_monthly
                        }
                        amortization_schedule_config {
                            term_start
                            term_end
                            annual_interest_amortization_min_month_term

                            principal_required
                            initiation_fee_premium_required
                            admin_fee_required
                            interest_required
                        }
                    }

                    repayment_info {
                        payment_method
                        repayment_frequency
                        elected_repayment_time_point
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_statement_details {
                        salary_date

                        salary_1 {
                            date_on_statement
                            balance_1_day_after
                            balance_2_days_after
                            balance_3_days_after
                        }
                        salary_2 {
                            date_on_statement
                            balance_1_day_after
                            balance_2_days_after
                            balance_3_days_after
                        }
                        salary_3 {
                            date_on_statement
                            balance_1_day_after
                            balance_2_days_after
                            balance_3_days_after
                        }
                    }

                    system_approval_results {
                        approval_method
                        reason
                        elected_collection_date

                        day1_after {
                            month1_qualifies
                            month2_qualifies
                            month3_qualifies
                        }
                        day2_after {
                            month1_qualifies
                            month2_qualifies
                            month3_qualifies
                        }
                        day3_after {
                            month1_qualifies
                            month2_qualifies
                            month3_qualifies
                        }
                    }
                }
            }
        }
    }
`

export const businessValidateLoanQuote = gql`
    mutation businessValidateLoanQuote($loan_quote_id: String!) {
        businessValidateLoanQuote(loan_quote_id: $loan_quote_id) {
            loan_quote {
                _id
                quote_number
            }
            overall_status
            validation_results {
                id
                rule_id
                rule_name
                loan_quote_param_value
                min
                max
                valid
                waived
                fail_reason
            }
        }
    }
`