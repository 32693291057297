<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Reverse Policy Transaction</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeReverseTransactionDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="transaction.to.name"
                                color="secondary"
                                label="Account From"
                                persistent-hint
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="transaction.from.name"
                                color="secondary"
                                label="Account To"
                                persistent-hint
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                :value="'REVERSE --- '+transaction.reference"
                                color="secondary"
                                label="Customer Reference"
                                hint="For EFT or CASH or ATM clients"
                                persistent-hint
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                :value="Math.abs(transaction.amount)"
                                color="secondary"
                                label="Amount"
                                type="number"
                                prefix="R"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Transaction</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        @click="saveTransaction()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getSystemFinanceAccounts } from "../gql/queries";
import { createFinanceTransaction } from "../gql/mutations";

export default {
    components: {
        
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        transaction: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        transaction_date_menu: false,
        submitting: false,

        backendActionItems: [],

        refreshing_system_finance_accounts_list: false,
        system_finance_accounts: [],
    }),
    methods: {
        closeReverseTransactionDialog() {
            eventBus.$emit('CLOSE_REVERSE_POLICY_TRANSACTION_DIALOG', this.server_resp_obj);
        },
        
        async saveTransaction() {
            this.submitting = true;
            try {
                const transactionObj = {
                    OP_TYPE: 'CREATE',
                    
                    // transaction_date: this.premium_details.transaction_date,
                    from: this.transaction.to._id,
                    to: this.transaction.from._id,
                    amount: Math.abs(parseFloat(this.transaction.amount)),
                    reference: 'REVERSE --- ' + this.transaction.reference,
                    additional_reference: `{"REVERSE": "${this.transaction.reference}", "PARENT_TRANS": "${this.transaction._id}"}`,
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createFinanceTransaction,
                                                variables: {
                                                    input: transactionObj
                                                },
                                            });

                this.submitting = false;
                if (res.data.finProcessFinanceAccountTransaction.errors === true) {
                    emitAlert(res.data.finProcessFinanceAccountTransaction.message);
                    return;
                }

                emitSuccess(res.data.finProcessFinanceAccountTransaction.message);

                this.server_resp_obj = res.data.finProcessFinanceAccountTransaction.business_object;
                this.closeReverseTransactionDialog();

                // Reset
                this.server_resp_obj = null;

            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.finProcessFinanceAccountTransaction.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshSystemAccountsLookupList() {
            this.refreshing_system_finance_accounts_list = true;
            try {
                // Load System Accounts
                const res = await this.$apollo
                                    .query({
                                        query: getSystemFinanceAccounts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_SYSTEM_ACCOUNT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_system_finance_accounts_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.system_finance_accounts = res.data.getBusinessObject.business_objects.filter(acc => {
                                                                                return (
                                                                                    acc.name === 'CARD_SWIPE' ||
                                                                                    acc.name === 'CASH' ||
                                                                                    acc.name === 'EFT' ||
                                                                                    acc.name === 'ATM'
                                                                                );
                                                                            });
            } catch (error) {
                this.refreshing_system_finance_accounts_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        try {
            // System finance accounts
            this.refreshing_system_finance_accounts_list = true;

            const res = await this.$apollo
                                .query({
                                    query: getSystemFinanceAccounts,
                                    // fetchPolicy: 'network-only',
                                    fetchPolicy: 'no-cache',
                                    errorPolicy: 'all',
                                    variables: {
                                        objectType: 'FINANCE_SYSTEM_ACCOUNT',
                                        pagination: {
                                            page: this.page,
                                            limit: this.limit
                                        }
                                    },
                                });
            this.refreshing_system_finance_accounts_list = false;
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }

            this.system_finance_accounts = res.data.getBusinessObject.business_objects.filter(acc => {
                                                                            return (
                                                                                acc.name === 'CARD_SWIPE' ||
                                                                                acc.name === 'CASH' ||
                                                                                acc.name === 'EFT' ||
                                                                                acc.name === 'ATM'
                                                                            );
                                                                        });
        } catch (error) {
            this.refreshing_system_finance_accounts_list = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>