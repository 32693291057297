var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0px","width":"100%","border-top":"3px solid #4590a0","border-bottom":"3px solid #4590a0"}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","height":"50"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.refreshCallRecordings()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Call Recordings")])]),_c('v-spacer'),((
                _vm.selectedRecording !== null &&
                _vm.selectedRecording.recording_imported === false
            ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.downloadCallRecording()}}},on),[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Download Recording ")],1)]}}],null,false,1404490506)},[_c('span',[_vm._v("Download Call Recording")])]):_vm._e(),((
                _vm.selectedRecording !== null &&
                _vm.selectedRecording.recording_imported === true
            ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black white--text","small":""},on:{"click":function($event){return _vm.playCallRecording()}}},on),[_c('v-icon',[_vm._v("mdi-play")]),_vm._v(" Play Recording ")],1)]}}],null,false,2842491940)},[_c('span',[_vm._v("Play Call Recording")])]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.document_details_headers,"items":_vm.call_recordings,"item-key":"_id","single-select":true,"hide-default-footer":"","loading":_vm.recordings_loading,"disable-pagination":true},on:{"click:row":_vm.selectDocumentsTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.recording_imported",fn:function(ref){
            var item = ref.item;
return [(item.recording_imported === true)?_c('td',[_vm._v("YES")]):_c('td',[_vm._v("NOT-YET")])]}},{key:"item.captured_by",fn:function(ref){
            var item = ref.item;
return [(item.captured_by !== null)?_c('td',[(item.captured_by.entity !== null)?_c('span',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_c('span',[_vm._v("N/A")])]):_vm._e()]}}])}),(_vm.selectedRecording !== null && _vm.recordingData !== null)?_c('ListenToCall',{attrs:{"dialog":_vm.open_listen_to_call_dialog,"recording_data":_vm.recordingData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }