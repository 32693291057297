<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshSchemes()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedScheme !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditSchemeDetailsDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>

                    <!-- <v-tooltip bottom v-if="selectedScheme !== null">
                        <template v-slot:activator="{ on }">
                            <v-badge
                                :color="selectedScheme.version_history.changes.length > 0 ? 'primary' : 'secondary'"
                                :content="selectedScheme.version_history.changes.length"
                            >
                                <v-btn
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    :disabled="selectedScheme.version_history.changes.length === 0"
                                    @click="openViewSchemeChangeHistory()"
                                >
                                    <v-icon>mdi-history</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <span>View Change History</span>
                    </v-tooltip> -->
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                    <!-- <v-card-actions>
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions> -->
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openOnboardingDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Onboard a Scheme</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(
                                selectedScheme !== null &&
                                selectedScheme.scheme_type === 'INSURANCE'
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="purple white--text"
                                    class="mx-2"
                                    @click="openGenerateSchemeAlloactionFormDialog()"
                                    v-on="on"
                                >
                                    <!-- <v-icon medium>add</v-icon> -->Generate Allocation Form
                                </v-btn>
                            </template>
                            <span>Generate Scheme Allocation Form</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="scheme_details_headers"
                    :items="schemes"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Created -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Captured By -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Address Sub Tab -->
                    <template>
                        <v-tab key="address_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Address
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Contact Details Sub Tab -->
                    <template>
                        <v-tab key="contacts_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Contact Details
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Banking Details Sub Tab -->
                    <template>
                        <v-tab key="bank_account_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Bank Account Details
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Transactions History Sub Tab -->
                    <template>
                        <v-tab key="transactions_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Transactions History
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Loan Premium Allocation-Priority Sub Tab -->
                    <template v-if="selectedScheme !== null">
                        <v-tab key="loan_premium_allocation_priority_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate" v-if="selectedScheme.scheme_type === 'LOAN'">
                                Loan Prem-Allocn Priority
                            </span>
                            <span class="tab-title text-truncate" v-if="selectedScheme.scheme_type === 'INSURANCE'">
                                Policies
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        
                        <!-- Buttons for the ADDRESSES sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0 && selectedScheme !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Scheme Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove Scheme Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Scheme Address</span>
                        </v-tooltip>

                        <!-- Buttons for the CONTACTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedScheme !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddContactsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Scheme Contacts</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedContact !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteContactsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Scheme Contacts</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedContact !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openEditContactDialog()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Scheme Contacts</span>
                        </v-tooltip>

                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 2 && selectedScheme !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Scheme Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteSchemeDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Scheme Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <!-- Buttons for the BANKING sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 3 && selectedScheme !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Scheme Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 3  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteBankingDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Scheme Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 3  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openEditBankingDialog()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Scheme Banking Details</span>
                        </v-tooltip>

                        <!-- Scheme transaction -->
                        <v-tooltip bottom v-if="sub_tabs === 4 && selectedScheme !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddPremiumPaymentEventDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Capture Payment Event</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 4 && selectedScheme !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshSchemeTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Payment Events</span>
                        </v-tooltip>

                        <v-tooltip
                            v-if="(
                                sub_tabs === 5 &&
                                selectedScheme !== null &&
                                selectedScheme.scheme_type === 'LOAN'
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openEditLoanPremiumAllocationDialog()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Allocation Priority</span>
                        </v-tooltip>

                        <!-- Policies Management -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedScheme !== null &&
                                selectedScheme.scheme_type === 'INSURANCE'
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openAddPolicyToSchemeDialog()"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Policy to Scheme</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedScheme !== null &&
                                selectedScheme.scheme_type === 'INSURANCE' &&
                                selectedPolicy !== null
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="red"
                                    small
                                    v-on="on"
                                    @click="removePolicyFromScheme()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove Policy from Scheme</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedScheme !== null &&
                                selectedScheme.scheme_type === 'INSURANCE' &&
                                selectedPolicy !== null
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    @click="updatePaymentArrangementDetails()"
                                >
                                    <v-icon>edit</v-icon> Update Payment Arrangement
                                </v-btn>
                            </template>
                            <span>Update Payment Arrangement Details</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Address Sub-Tab Content -->
                    <v-tab-item key="address_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="address_details_headers"
                            :items="scheme_addresses"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAddressTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Contact Details Sub-Tab Content -->
                    <v-tab-item key="contacts_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="contact_details_headers"
                            :items="scheme_contact_details"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectContactsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Primary Contact -->
                            <template v-slot:[`item.primary`]="{ item }">
                                <td>{{item.primary ? 'Yes' : 'No'}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="scheme_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Bankind Details Sub-Tab Content -->
                    <v-tab-item key="bank_account_details_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="bank_account_details_headers"
                            :items="scheme_bank_account_details"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectBankingTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Transactions History Sub-Tab Content -->
                    <v-tab-item key="transactions_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :loading="transactions_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display Formated transaction date -->
                            <template v-slot:[`item.transaction_date`]="{ item }">
                                <td>{{item.transaction_date !== null ? item.transaction_date.split('T')[0] : ''}}</td>
                            </template>
                            <!-- Display Formated created date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                            </template>
                            <!-- Display Formated amount -->
                            <template v-slot:[`item.amount`]="{ item }">
                                <td>R {{item.amount}}</td>
                            </template>
                            <!-- Display Formated balance -->
                            <template v-slot:[`item.balance`]="{ item }">
                                <td>R {{item.balance}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Loan Premium Allocation Sub-Tab Content -->
                    <v-tab-item key="loan_premium_allocation_priority_sub_tab">
                        <!-- LOAN PREM ALLOCATION -->
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                            v-if="(
                                selectedScheme !== null &&
                                selectedScheme.scheme_type === 'LOAN'
                            )"
                        >
                            <v-card-text v-if="selectedScheme !== null">
                                <v-row v-if="selectedScheme.scheme_type === 'LOAN'">
                                    <v-col cols="12" sm="12"><b>Principal/Capital: </b>{{ selectedScheme.loan_premium_allocation_priority.principal }}</v-col>
                                    <v-col cols="12" sm="12"><b>Interest: </b>{{ selectedScheme.loan_premium_allocation_priority.interest }}</v-col>
                                    <v-col cols="12" sm="12"><b>Monthly Admin Fees: </b>{{ selectedScheme.loan_premium_allocation_priority.monthly_admin_fees }}</v-col>
                                    <v-col cols="12" sm="12"><b>Initiation Fees: </b>{{ selectedScheme.loan_premium_allocation_priority.initiation_fees }}</v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <!-- SCHEME POLICIES -->
                        <v-data-table
                            v-if="(
                                selectedScheme !== null &&
                                selectedScheme.scheme_type === 'INSURANCE'
                            )"
                            fixed-header
                            :height="sub_tab_height"
                            :headers="scheme_policies_headers"
                            :items="scheme_policies"
                            item-key="policy_number"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectPolicyTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :loading="transactions_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:item.principal_member="{ item }">
                                <td>{{item.principal_member.name + ' ' + item.principal_member.surname + ' (' + item.principal_member.id_number + ')'}}</td>
                            </template>
                            <!-- Display Formated capture date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated effective date -->
                            <template v-slot:item.effective_date="{ item }">
                                <td>{{item.effective_date !== null ? item.effective_date.split('T')[0] : ''}}</td>
                            </template>
                            <!-- Display Formated end date -->
                            <template v-slot:item.end_date="{ item }">
                                <td>{{item.end_date !== null ? item.end_date.split('T')[0] : ''}}</td>
                            </template>
                            <!-- Product premium -->
                            <template v-slot:item.premium="{ item }" >
                                <td>R {{ item.premium }}</td>
                            </template>
                            <!-- Display Formated payment method -->
                            <template v-slot:item.payment_information.premium_payer.payment_method="{ item }">
                                <td>{{item.payment_information.premium_payer.payment_method}}</td>
                            </template>
                            <!-- Display Formated percentage amount -->
                            <template v-slot:item.payment_information.scheme.percentage_amount="{ item }">
                                <!-- <td>{{item.payment_information.scheme.percentage_amount}}</td> -->
                                <td v-if="item.payment_information.scheme.percentage_amount > 0" style="background:green; color:white; border: 1px solid white">{{item.payment_information.scheme.percentage_amount}}</td>
                                <td v-else style="background:red; color:white; border: 1px solid white">{{item.payment_information.scheme.percentage_amount}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <OnboardingDialog
            :dialog="open_onboarding_dialog"
        />
        <EditSchemeDetailsDialog
            v-if="selectedScheme !== null"
            :dialog="open_edit_scheme_dialog"
            :scheme_original="selectedScheme"
            :scheme_copy="selectedSchemeCopy"
        />
        <EditSchemeLoanPremiumAllocationDialog
            v-if="selectedScheme !== null"
            :dialog="open_edit_loan_premium_allocation_dialog"
            :scheme_original="selectedScheme"
            :scheme_copy="selectedSchemeCopy"
        />

        <!-- Address dialogs -->
        <AddAddressesDialog
            v-if="selectedScheme !== null"
            :dialog="open_add_address_dialog"
            :scheme_id="selectedScheme._id"
        />
        <EditAddressDialog
            v-if="selectedScheme !== null && selectedAddress !== null"
            :dialog="open_edit_address_dialog"
            :scheme_id="selectedScheme._id"
            :address_original="selectedAddress"
            :address_copy="selectedAddressCopy"
        />
        <DeleteAddressesDialog
            v-if="selectedScheme !== null"
            :dialog="open_delete_address_dialog"
            :scheme="selectedScheme"
            :address="selectedAddress"
        />

        <!-- Contacts dialogs -->
        <AddContactsDialog
            v-if="selectedScheme !== null"
            :dialog="open_add_contact_dialog"
            :scheme_id="selectedScheme._id"
        />
        <EditContactDialog
            v-if="selectedScheme !== null && selectedContact !== null"
            :dialog="open_edit_contact_dialog"
            :scheme_id="selectedScheme._id"
            :contact_original="selectedContact"
            :contact_copy="selectedContactCopy"
        />
        <DeleteContactsDialog
            v-if="selectedContact !== null"
            :dialog="open_delete_contact_dialog"
            :scheme="selectedScheme"
            :contact="selectedContact"
        />

        <!-- Document Dialogs -->
        <AddSchemeDocumentDialog
            v-if="selectedScheme !== null"
            :dialog="open_add_document_dialog"
            :scheme="selectedScheme"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_scheme_document_dialog"
            :document="selectedDocument"
            param_path="scheme"
            :param_id="selectedScheme._id"
        />
        <DeleteSchemeDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_delete_scheme_document_dialog"
            :scheme="selectedScheme"
            :document="selectedDocument"
        />

        <!-- Banking Dialogs -->
        <AddBankingDialog
            v-if="selectedScheme !== null"
            :dialog="open_add_banking_dialog"
            :scheme_id="selectedScheme._id"
        />
        <EditBankingDialog
            v-if="selectedScheme !== null && selectedBanking !== null"
            :dialog="open_edit_banking_dialog"
            :scheme_id="selectedScheme._id"
            :banking_original="selectedBanking"
            :banking_copy="selectedBankingCopy"
        />
        <DeleteBankingDialog
            v-if="selectedBanking !== null"
            :dialog="open_delete_banking_dialog"
            :scheme="selectedScheme"
            :banking="selectedBanking"
        />

        <!-- Transaction History -->
        <AddSchemePremiumPaymentDialog
            v-if="selectedScheme !== null"
            :dialog="open_add_premium_payment_dialog"
            :scheme="selectedScheme"
        />

        <GenerateSchemeAllocationFormDialog
            v-if="selectedScheme !== null"
            :dialog="open_generate_scheme_allocation_form_dialog"
            :scheme="selectedScheme"
        />

        <SearchSchemesDialog
            :dialog="open_search_schemes_dialog"
            @CLOSE_SCHEME_SEARCH_DIALOG="closeSearchSchemesDialog()"
        />

        <AddPolicyToSchemeDialog
            v-if="selectedScheme !== null"
            :dialog="open_add_policy_to_scheme"
            :scheme="selectedScheme"
        />

        <UpdatePaymentArrangementDialog
            v-if="selectedPolicy !== null"
            :dialog="open_update_payment_arrangement_details_dialog"
            :policy_original="selectedPolicy"
            :policy_copy="selectedPolicyCopy"
        />
        
        <!-- <ViewSchemeChangeHistoryDialog
            v-if="selectedScheme !== null"
            :dialog="open_view_scheme_change_history_dialog"
            :change_history="selectedScheme.version_history"
        /> -->
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import OnboardingDialog from './dialogs/add_scheme';
import EditSchemeDetailsDialog from './dialogs/edit_scheme_details';
import EditSchemeLoanPremiumAllocationDialog from './dialogs/edit_scheme_loan_premium_allocation';

import AddAddressesDialog from './dialogs/add_scheme_addresses';
import EditAddressDialog from './dialogs/edit_scheme_address';
import DeleteAddressesDialog from './dialogs/delete_scheme_address';

import AddContactsDialog from './dialogs/add_scheme_contacts';
import EditContactDialog from './dialogs/edit_scheme_contact';
import DeleteContactsDialog from './dialogs/delete_scheme_contact';

import AddSchemeDocumentDialog from './dialogs/add_scheme_documents';
import DeleteSchemeDocumentDialog from './dialogs/delete_scheme_document';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';

import AddBankingDialog from './dialogs/add_scheme_banking';
import EditBankingDialog from './dialogs/edit_scheme_banking';
import DeleteBankingDialog from './dialogs/delete_scheme_banking';

import SearchSchemesDialog from './dialogs/search_scheme';
import AddPolicyToSchemeDialog from './dialogs/add_policy_to_scheme.vue';
import UpdatePaymentArrangementDialog from './dialogs/update_payment_arrangement_details.vue';
// import ViewSchemeChangeHistoryDialog from './dialogs/view_scheme_change_history';

import AddSchemePremiumPaymentDialog from './dialogs/add_scheme_premium_payment.vue';
import GenerateSchemeAllocationFormDialog from './dialogs/generate_scheme_allocation_form.vue';

import { emitAlert, emitSuccess } from "../../utils/api";
import { eventBus } from "../../main";

import { getSchemes, getFinanceTransactionsList, getPolicies } from "./gql/queries";
import { businessProcessPolicy } from "./gql/mutations";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        OnboardingDialog,
        SearchSchemesDialog,
        EditSchemeDetailsDialog,
        EditSchemeLoanPremiumAllocationDialog,
        // ViewSchemeChangeHistoryDialog,

        AddAddressesDialog,
        EditAddressDialog,
        DeleteAddressesDialog,

        AddContactsDialog,
        EditContactDialog,
        DeleteContactsDialog,

        AddSchemeDocumentDialog,
        DeleteSchemeDocumentDialog,
        ViewDocumentDialog,

        AddBankingDialog,
        EditBankingDialog,
        DeleteBankingDialog,

        AddSchemePremiumPaymentDialog,
        GenerateSchemeAllocationFormDialog,
        AddPolicyToSchemeDialog,
        UpdatePaymentArrangementDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedScheme: null,
        selectedRow: null,

        // addresses sub-tab data-table row
        selectedAddress: null,
        selectedAddressRow: null,
        selectedAddressCopy: {
            type:  null,
            line1: null,
            line2: null,
            line3: null,
            line4: null
        },
        
        // contacts sub-tab data-table row
        selectedContact: null,
        selectedContactRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,
        
        // banking sub-tab data-table row
        selectedBanking: null,
        selectedBankingRow: null,

        selectedPolicy: null,
        selectedPolicyRow: null,

        selectedContactCopy: {
            type:  null,
            primary: null,
            details: null
        },
        selectedBankingCopy: {
            type:  null,
            bank: null,
            account_type: null,
            account_number: null
        },
        selectedSchemeCopy: {
            name:  null,
        },
        

        scheme_details_headers: [
            { text: 'Scheme Number', value: 'scheme_number' },
            { text: 'Name', value: 'name' },
            { text: 'Scheme Type', value: 'scheme_type' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        address_details_headers: [
            { text: 'Address Type', value: 'type' },
            { text: 'Line 1', value: 'line1' },
            { text: 'Line 2', value: 'line2' },
            { text: 'Line 3', value: 'line3' },
            { text: 'Postal Code', value: 'line4' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        bank_account_details_headers: [
            { text: 'Purpose', value: 'purpose' },
            { text: 'Bank', value: 'bank.name' },
            { text: 'Account Type', value: 'account_type' },
            { text: 'Account Number', value: 'account_number' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        contact_details_headers: [
            { text: 'Contact Type', value: 'type' },
            { text: 'Primary Contact', value: 'primary' },
            { text: 'Contact Details', value: 'details' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Created Date', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'System Reference', value: 'reference' },
            { text: 'Customer Reference', value: 'additional_reference' },{ text: 'Reference', value: 'reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
        ],
        scheme_policies_headers: [
            { text: 'Policy Number', value: 'policy_number' },
            { text: 'Principal Member', value: 'principal_member' },
            { text: 'Product', value: 'product.name' },
            { text: 'Effective Date', value: 'effective_date' },
            { text: 'Premium', value: 'premium' },
            { text: 'Payment Method', value: 'payment_information.premium_payer.payment_method' },
            { text: 'Scheme %', value: 'payment_information.scheme.percentage_amount' },
            { text: 'Status', value: 'status', width: '5%' },
            { text: 'Date Captured', value: 'created' },
        ],
        
        // Table data models
        scheme_addresses: [],
        scheme_bank_account_details: [],
        scheme_contact_details: [],
        scheme_documents: [],
        transactions: [],
        scheme_policies: [],
        

        // Dialogs
        
        schemes: [],
        open_onboarding_dialog: false,
        open_generate_scheme_allocation_form_dialog: false,
        open_edit_scheme_dialog: false,
        open_edit_loan_premium_allocation_dialog: false,
        open_search_schemes_dialog: false,
        
        // Address dialogs
        open_add_address_dialog: false,
        open_delete_address_dialog: false,
        open_edit_address_dialog: false,

        // Contacts dialogs
        open_add_contact_dialog: false,
        open_edit_contact_dialog: false,
        open_delete_contact_dialog: false,
        // Documents dialogs
        open_add_document_dialog: false,
        open_delete_scheme_document_dialog: false,
        open_view_scheme_document_dialog: false,
        // Banking dialogs
        open_add_banking_dialog: false,
        open_edit_banking_dialog: false,
        open_delete_banking_dialog: false,
        // Premium
        open_add_premium_payment_dialog: false,
        // Policies
        open_add_policy_to_scheme: false,

        open_view_scheme_change_history_dialog: false,
        open_update_payment_arrangement_details_dialog: false,
        
        data_table_loading: false,

        transactions_loading: false,


        page: 1,
        limit: 50,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openOnboardingDialog() {
            this.open_onboarding_dialog = true;
        },
        openGenerateSchemeAlloactionFormDialog() {
            this.open_generate_scheme_allocation_form_dialog = true;
        },
        openEditSchemeDetailsDialog() {
            this.open_edit_scheme_dialog = true;
        },
        openEditLoanPremiumAllocationDialog() {
            this.open_edit_loan_premium_allocation_dialog = true;
        },
        openSearchSchemesDialog() {
            this.open_search_schemes_dialog = true;
        },
        openAddAddressDialog() {
            this.open_add_address_dialog = true;
        },
        openDeleteAddressDialog() {
            this.open_delete_address_dialog = true;
        },
        openEditAddressDialog() {
            this.selectedAddressCopy = Object.assign({}, this.selectedAddress);
            this.open_edit_address_dialog = true;
        },
        openAddContactsDialog() {
            this.open_add_contact_dialog = true;
        },
        openEditContactDialog() {
            this.selectedContactCopy = Object.assign({}, this.selectedContact);
            this.open_edit_contact_dialog = true;
        },
        openDeleteContactsDialog() {
            this.open_delete_contact_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openDeleteSchemeDocumentDialog() {
            this.open_delete_scheme_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_scheme_document_dialog = true;
        },
        openAddBankingDialog() {
            this.open_add_banking_dialog = true;
        },
        openEditBankingDialog() {
            this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            this.open_edit_banking_dialog = true;
        },
        openDeleteBankingDialog() {
            this.open_delete_banking_dialog = true;
        },
        // openViewSchemeChangeHistory() {
        //     this.open_view_scheme_change_history_dialog = true;
        // },
        openAddPremiumPaymentEventDialog() {
            this.open_add_premium_payment_dialog = true;
        },
        openAddPolicyToSchemeDialog() {
            this.open_add_policy_to_scheme = true;
        },
        updatePaymentArrangementDetails() {
            this.open_update_payment_arrangement_details_dialog = true;
        },
        async removePolicyFromScheme() {
            this.transactions_loading = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'UPDATE_POLICY_SCHEME',
                    _id: this.selectedPolicy._id,

                    scheme: null
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessPolicy.errors === true) {
                    emitAlert(res.data.businessProcessPolicy.message);
                    this.transactions_loading = false;
                    return;
                }

                emitSuccess(res.data.businessProcessPolicy.message);
                this.transactions_loading = false;

                // Cleanup
                this.selectedPolicy = null;
                this.selectedPolicyRow = null;

                this.refreshSchemePolicies();
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },


        // Edit form reseters
        resetAddressEditForm(updatedAddress) {
            if (
                updatedAddress.type !== this.selectedAddress.type ||
                updatedAddress.line1 !== this.selectedAddress.line1 ||
                updatedAddress.line2 !== this.selectedAddress.line2 ||
                updatedAddress.line3 !== this.selectedAddress.line3 ||
                updatedAddress.line4 !== this.selectedAddress.line4
            ) {
                this.selectedAddressCopy = Object.assign({}, this.selectedAddress);
            }
        },
        resetContactEditForm(updatedContact) {
            if (
                updatedContact.type !== this.selectedContact.type ||
                updatedContact.primary !== this.selectedContact.primary ||
                updatedContact.details !== this.selectedContact.details
            ) {
                this.selectedContactCopy = Object.assign({}, this.selectedContact);
            }
        },
        resetBankingEditForm(updatedBanking) {
            if (
                updatedBanking.purpose !== this.selectedBanking.purpose ||
                updatedBanking.bank !== this.selectedBanking.bank ||
                updatedBanking.account_type !== this.selectedBanking.account_type ||
                updatedBanking.account_number !== this.selectedBanking.account_number
            ) {
                this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            }
        },
        resetSchemeEditForm(updatedScheme) {
            if (
                updatedScheme.name !== this.selectedScheme.name
            ) {
                this.selectedSchemeCopy = Object.assign({}, this.selectedScheme);
            }
        },
        resetSchemeLoanPremiumAllocationEditForm() {
            // this.selectedSchemeCopy = Object.assign({}, this.selectedScheme);
            this.selectedSchemeCopy.loan_premium_allocation_priority.initiation_fees = this.selectedScheme.loan_premium_allocation_priority.initiation_fees;
            this.selectedSchemeCopy.loan_premium_allocation_priority.interest = this.selectedScheme.loan_premium_allocation_priority.interest;
            this.selectedSchemeCopy.loan_premium_allocation_priority.monthly_admin_fees = this.selectedScheme.loan_premium_allocation_priority.monthly_admin_fees;
            this.selectedSchemeCopy.loan_premium_allocation_priority.principal = this.selectedScheme.loan_premium_allocation_priority.principal;
        },
        resetPolicyEditForm(updatedPolicy) {
            // console.log(updatedPolicy)
            console.log('HE')

            if (updatedPolicy.scheme !== null) {
                if (
                    updatedPolicy.product._id !== this.selectedPolicy.product._id ||
                    updatedPolicy.scheme._id !== this.selectedPolicy.scheme._id ||

                    // updatedPolicy.scheme_level_payment !== this.selectedPolicy.scheme_level_payment ||
                    updatedPolicy.elected_payment_date !== this.selectedPolicy.elected_payment_date ||

                    updatedPolicy.payment_information.premium_payer.payment_method !== this.selectedPolicy.payment_information.premium_payer.payment_method ||
                    updatedPolicy.payment_information.premium_payer.percentage_amount !== this.selectedPolicy.payment_information.premium_payer.percentage_amount ||
                    
                    updatedPolicy.payment_information.scheme.payment_method !== this.selectedPolicy.payment_information.scheme.payment_method ||
                    updatedPolicy.payment_information.scheme.percentage_amount !== this.selectedPolicy.payment_information.scheme.percentage_amount
                ) {
                    // this.selectedPolicyCopy = Object.assign({}, this.selectedPolicy);
                    this.selectedPolicyCopy = JSON.parse( JSON.stringify(this.selectedPolicy) );
                }
            } else {
                if (
                    updatedPolicy.product._id !== this.selectedPolicy.product._id ||
                    // updatedPolicy.scheme._id !== this.selectedPolicy.scheme._id ||

                    // updatedPolicy.scheme_level_payment !== this.selectedPolicy.scheme_level_payment ||
                    updatedPolicy.elected_payment_date !== this.selectedPolicy.elected_payment_date ||

                    updatedPolicy.payment_information.premium_payer.payment_method !== this.selectedPolicy.payment_information.premium_payer.payment_method ||
                    updatedPolicy.payment_information.premium_payer.percentage_amount !== this.selectedPolicy.payment_information.premium_payer.percentage_amount ||
                    
                    updatedPolicy.payment_information.scheme.payment_method !== this.selectedPolicy.payment_information.scheme.payment_method ||
                    updatedPolicy.payment_information.scheme.percentage_amount !== this.selectedPolicy.payment_information.scheme.percentage_amount
                ) {
                    // this.selectedPolicyCopy = Object.assign({}, this.selectedPolicy);
                    this.selectedPolicyCopy = JSON.parse( JSON.stringify(this.selectedPolicy) );
                }
            }
            
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeOnboardingDialog(new_scheme) {
            if (new_scheme !== null) {
                this.schemes.push(new_scheme);
            }
            this.open_onboarding_dialog = false;
        },
        closeEditSchemeDialog(new_scheme) {
            if (new_scheme !== null) {
                const obj_index = this.schemes.map(obj => { return obj._id; }).indexOf(new_scheme._id)
                this.schemes[obj_index] = new_scheme;

                 // Deep copy schemes for refreshing main data table
                const temp_arr = Array.from(this.schemes);
                this.schemes = temp_arr;

                // Clear sub-tabs as the main row get automatically unselected
                this.scheme_addresses = [];
                this.scheme_contact_details = [];
                this.scheme_documents = [];
                this.scheme_bank_account_details = [];

                this.selectedScheme = new_scheme;
            }

            this.open_edit_scheme_dialog = false;
        },
        closeEditLoanPremiumAllocationDialog(new_scheme) {
            if (new_scheme !== null) {
                const obj_index = this.schemes.map(obj => { return obj._id; }).indexOf(new_scheme._id)
                this.schemes[obj_index] = new_scheme;

                 // Deep copy schemes for refreshing main data table
                const temp_arr = Array.from(this.schemes);
                this.schemes = temp_arr;

                // Clear sub-tabs as the main row get automatically unselected
                this.scheme_addresses = [];
                this.scheme_contact_details = [];
                this.scheme_documents = [];
                this.scheme_bank_account_details = [];

                this.selectedScheme = new_scheme;
                this.selectedSchemeCopy = Object.assign({}, new_scheme);
            }

            this.open_edit_loan_premium_allocation_dialog = false;
        },

        updateUITableModel(new_scheme) {
            if (new_scheme !== null) {
                const obj_index = this.schemes.map(obj => { return obj._id; }).indexOf(new_scheme._id)
                this.schemes[obj_index] = new_scheme;

                 // Deep copy schemes for refreshing main data table
                const temp_arr = Array.from(this.schemes);
                this.schemes = temp_arr;

                // Also update the sub-tabs data
                this.scheme_addresses = new_scheme.addresses.filter(addr => { return !addr.deleted });
                this.scheme_contact_details = new_scheme.contact_details.filter(contact => { return !contact.deleted });
                this.scheme_documents = new_scheme.documents.filter(doc => { return !doc.deleted });
                this.scheme_bank_account_details = new_scheme.bank_account_details.filter(banking => { return !banking.deleted });

                // Reset data-table items
                this.selectedScheme = new_scheme;
                // this.unselectSubTabRows();
            }
        },
        closeAddAddressesDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_add_address_dialog = false;
        },
        closeDeleteAddressesDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_delete_address_dialog = false;
        },
        closeEditAddressDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_edit_address_dialog = false;
        },
        // closeViewSchemeChangeHistoryDialog() {
        //     this.open_view_scheme_change_history_dialog = false;
        // },
        closeAddContactsDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_add_contact_dialog = false;
        },
        closeEditContactDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_edit_contact_dialog = false;
        },
        closeDeleteContactDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_delete_contact_dialog = false;

            this.selectedContactRow = null;
            this.selectedContact = null;

            this.selectedContactCopy = {
                type:  null,
                primary: null,
                details: null
            };
        },
        closeAddSchemeDocumentsDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_add_document_dialog = false;
        },
        closeDeleteSchemeDocumentDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_delete_scheme_document_dialog = false;

            this.selectedDocumentRow = null;
            this.selectedDocument = null;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_scheme_document_dialog = false;
        },
        closeAddBankingDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_add_banking_dialog = false;
        },
        closeEditBankingDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_edit_banking_dialog = false;
        },
        closeDeleteBankingDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_delete_banking_dialog = false;

            this.selectedBankingRow = null;
            this.selectedBanking = null;

            this.selectedBankingCopy = {
                type:  null,
                bank: null,
                account_type: null,
                account_number: null
            };
        },
        closeSearchSchemesDialog() {
            this.open_search_schemes_dialog = false;
        },
        closeAddPremiumPaymentDialog(new_transaction) {
            if (new_transaction !== null) {
                // Prepend to transactions array
                this.transactions.unshift(new_transaction);

                // Prepare balance
                if (new_transaction.from._id === this.selectedScheme._id) {
                    // new_transaction.balance = new_transaction.from_running_balance;
                    new_transaction.balance = new_transaction.balance_from;
                }

                if (new_transaction.to._id === this.selectedScheme._id) {
                    // new_transaction.balance = new_transaction.to_running_balance;
                    new_transaction.balance = new_transaction.balance_to;
                }
            }
            this.open_add_premium_payment_dialog = false;
        },
        closeGenerateSchemeAlloactionFormDialog() {
            this.open_generate_scheme_allocation_form_dialog = false;
        },
        closeAddPolicyToSchemeDialog(new_policy) {
            if (new_policy !== null) {
                // Prepend
                this.scheme_policies.unshift(new_policy);
            }
            this.open_add_policy_to_scheme = false;
        },
        closeUpdatePaymentArrangementDetails(new_policy) {
            // this.updateUITableModel(new_policy);

            if (new_policy !== null) {
                const obj_index = this.scheme_policies.map(obj => { return obj._id; }).indexOf(new_policy._id)
                this.scheme_policies[obj_index] = new_policy;

                 // Deep copy scheme_policies for refreshing main data table
                const temp_arr = Array.from(this.scheme_policies);
                this.scheme_policies = temp_arr;

                // Reset data-table items
                this.selectedPolicy = new_policy;
                this.selectedPolicyCopy = new_policy;
            }

            this.open_update_payment_arrangement_details_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect addresses sub-tab row
            this.selectedAddress = null;
            if (this.selectedAddressRow !== null) {
                this.selectedAddressRow.select(false);
            }

            // Unselect contacts sub-tab row
            this.selectedContact = null;
            if (this.selectedContactRow !== null) {
                this.selectedContactRow.select(false);
            }

            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }

            // Unselect banking sub-tab row
            this.selectedBanking = null;
            if (this.selectedBankingRow !== null) {
                this.selectedBankingRow.select(false);
            }
        },
        async selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedScheme = item;
                // Update scheme addresses model
                this.scheme_addresses = item.addresses.filter(addr => { return !addr.deleted });
                // Update scheme contact details model
                this.scheme_contact_details = item.contact_details.filter(contact => { return !contact.deleted });
                // Update scheme documents model
                this.scheme_documents = item.documents.filter(doc => { return !doc.deleted });
                // Update scheme banking details
                this.scheme_bank_account_details = item.bank_account_details.filter(banking => { return !banking.deleted });
                // Transactions
                this.transactions = [];

                if (this.sub_tabs === 5) { // Policies
                    if (
                        this.selectedScheme !== null &&
                        this.selectedScheme.scheme_type === 'INSURANCE'
                    ) {
                        await this.refreshSchemePolicies();
                    }
                }

                // Set selected scheme copy
                this.selectedSchemeCopy = Object.assign({}, this.selectedScheme);
            } else {
                this.selectedScheme = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update scheme addresses model
                this.scheme_addresses = [];
                // Update scheme contact details model
                this.scheme_contact_details = [];
                // Update scheme documents model
                this.scheme_documents = [];
                // Update scheme banking details
                this.scheme_bank_account_details = [];
                // Transactions
                this.transactions = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectAddressTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAddress = item;
                this.selectedAddressRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAddress = null;
                this.selectedAddressRow = null;
            }
        },
        selectContactsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedContact = item;
                this.selectedContactRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedContact = null;
                this.selectedContactRow = null;
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        selectBankingTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedBanking = item;
                this.selectedBankingRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedBanking = null;
                this.selectedBankingRow = null;
            }
        },
        selectPolicyTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedPolicy = item;
                this.selectedPolicyRow = item_metadata;

                this.selectedPolicyCopy = JSON.parse( JSON.stringify(item) );
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedPolicy = null;
                this.selectedPolicyRow = null;

                this.selectedPolicyCopy = null;
            }
        },
        tobeDefined(){},

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.schemes = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    
        async refreshSchemes() {
            this.data_table_loading = true;
            this.schemes = [];

            this.page = 1;
            this.limit = 50;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.schemes = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshSchemeTransactions() {
            try {
                this.transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                account_id: this.selectedScheme._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }


                this.transactions_loading = false;
                this.transactions = res.data.getBusinessObject.business_objects;

                // Prepare balance
                // let runningBalance = 0;
                // let runningAmount = 0;
                for (const trans of this.transactions) {
                    if (trans.from._id === this.selectedScheme._id) {
                        trans.amount = -trans.amount;
                        trans.balance = trans.balance_from;
                    }

                    if (trans.to._id === this.selectedScheme._id) {
                        trans.balance = trans.balance_to;
                    }

                    // if (this.transactions.indexOf(trans) === 0) {
                    //     if (trans.from._id === this.selectedScheme._id) {
                    //         // trans.balance = trans.from_running_balance;
                    //         trans.balance = trans.from.balance;
                    //     }
    
                    //     if (trans.to._id === this.selectedScheme._id) {
                    //         // trans.balance = trans.to_running_balance;
                    //         trans.balance = trans.to.balance;
                    //     }

                    //     runningBalance = trans.balance;
                    //     runningAmount = trans.amount;
                    // } else {
                    //     // NB: We're working the running balances backwards, so we've filpped the signs


                    //     if (trans.from._id === this.selectedScheme._id) {
                    //         // trans.balance = trans.from_running_balance;

                    //         // runningBalance = runningBalance + trans.amount;
                    //         runningBalance = runningBalance + runningAmount;
                    //         // trans.balance = trans.from.balance;
                    //         // trans.balance = runningBalance;

                    //     }
    
                    //     if (trans.to._id === this.selectedScheme._id) {
                    //         // trans.balance = trans.to_running_balance;

                    //         // runningBalance = runningBalance - trans.amount;
                    //         runningBalance = runningBalance - runningAmount;
                    //         // trans.balance = trans.to.balance;
                    //         // trans.balance = runningBalance;
                    //     }
                    //     trans.balance = runningBalance;
                    //     runningAmount = trans.amount;
                    // }
                }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshSchemePolicies() {
            this.transactions_loading = true;
            try {
                // Get scheme policies
                const res = await this.$apollo
                                    .query({
                                        query: getPolicies,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'POLICY',
                                            pagination: {
                                                page: 1,
                                                limit: 500
                                            },
                                            searchInput: {
                                                scheme: this.selectedScheme._id
                                            }
                                        },
                                    });
                this.transactions_loading = false;

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }

                this.scheme_policies = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeOnboardingDialog);
        eventBus.$on('CLOSE_EDIT_SCHEME_DIALOG', this.closeEditSchemeDialog);
        eventBus.$on('CLOSE_EDIT_LOAN_PREMIUM_ALLOCATION_DIALOG', this.closeEditLoanPremiumAllocationDialog);
        eventBus.$on('CLOSE_ADD_SCHEME_ADDRESS_DIALOG', this.closeAddAddressesDialog);
        eventBus.$on('CLOSE_EDIT_SCHEME_ADDRESS_DIALOG', this.closeEditAddressDialog);
        eventBus.$on('CLOSE_DELETE_SCHEME_ADDRESS_DIALOG', this.closeDeleteAddressesDialog);

        eventBus.$on('CLOSE_ADD_SCHEME_CONTACTS_DIALOG', this.closeAddContactsDialog);
        eventBus.$on('CLOSE_EDIT_SCHEME_CONTACT_DIALOG', this.closeEditContactDialog);
        eventBus.$on('CLOSE_DELETE_SCHEME_CONTACT_DIALOG', this.closeDeleteContactDialog);

        eventBus.$on('CLOSE_ADD_SCHEME_DOCUMENTS_DIALOG', this.closeAddSchemeDocumentsDialog);
        eventBus.$on('CLOSE_DELETE_SCHEME_DOCUMENT_DIALOG', this.closeDeleteSchemeDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);

        eventBus.$on('CLOSE_ADD_SCHEME_BANKING_DIALOG', this.closeAddBankingDialog);
        eventBus.$on('CLOSE_EDIT_SCHEME_BANKING_DIALOG', this.closeEditBankingDialog);
        eventBus.$on('CLOSE_DELETE_SCHEME_BANKING_DIALOG', this.closeDeleteBankingDialog);
        eventBus.$on('CLOSE_GENERATE_SCHEME_ALLOCATION_FORM_DIALOG', this.closeGenerateSchemeAlloactionFormDialog);
        eventBus.$on('CLOSE_ADD_POLICY_TO_SCHEME_DIALOG', this.closeAddPolicyToSchemeDialog);
        eventBus.$on('CLOSE_UPDATE_PAYMENT_ARRANGEMENT_DIALOG_2', this.closeUpdatePaymentArrangementDetails);

        eventBus.$on('RESET_SCHEME_EDIT_FORM', this.resetSchemeEditForm);
        eventBus.$on('RESET_ADDRESS_EDIT_FORM', this.resetAddressEditForm);
        eventBus.$on('RESET_CONTACT_EDIT_FORM', this.resetContactEditForm);
        eventBus.$on('RESET_BANKING_EDIT_FORM', this.resetBankingEditForm);
        eventBus.$on('RESET_LOAN_PREMIUM_ALLOCATION_EDIT_FORM', this.resetSchemeLoanPremiumAllocationEditForm);
        eventBus.$on('RESET_POLICY_EDIT_FORM_2', this.resetPolicyEditForm);

        eventBus.$on('CLOSE_ADD_SCHEME_PREMIUM_PAYMENT_DIALOG', this.closeAddPremiumPaymentDialog);

        // eventBus.$on('CLOSE_VIEW_SCHEME_CHANGE_HISTORY_DIALOG', this.closeViewSchemeChangeHistoryDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.schemes = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        'sub_tabs': async function(val) {
            if (val === 5) { // Policies
                if (
                    this.selectedScheme !== null &&
                    this.selectedScheme.scheme_type === 'INSURANCE'
                ) {
                    await this.refreshSchemePolicies();
                }
            }
        }
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>