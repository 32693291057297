<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="3" style="margin-top:10px">
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshSchemeAllocations()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                    <!-- <v-card-actions>
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions> -->
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="5" style="margin-top:10px">
                    <div class="text-right">
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedSchemeAllocation !== null &&
                                selectedSchemeAllocation.status === 'NOT-PROCESSED'
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary white--text"
                                    class="mx-2"
                                    @click="processSchemeAllocation()"
                                    v-on="on"
                                >
                                    <v-icon medium>mdi-cog</v-icon> Process Scheme Allocation
                                </v-btn>
                            </template>
                            <span>Process Scheme Allocation</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openOnboardingDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon> Scheme-Allocation
                                </v-btn>
                            </template>
                            <span>Capture Scheme-Allocation</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="scheme_allocation_details_headers"
                    :items="scheme_allocations"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated allocation number -->
                    <template v-slot:item.allocation_number="{ item }">
                        <td>SA-{{item.allocation_number}}</td>
                    </template>
                    <!-- Transaction Date -->
                    <template v-slot:[`item.transaction_date`]="{ item }">
                        <td>{{item.transaction_date.split('T')[0]}}</td>
                    </template>
                    <!-- Created -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Captured By -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                    <!-- Scheme -->
                    <template v-slot:[`item.scheme`]="{ item }">
                        <td>{{item.scheme.name}} ({{ item.scheme.scheme_number }})</td>
                    </template>
                    <!-- Bank Feed -->
                    <template v-slot:[`item.bank_feed_item`]="{ item }">
                        <td>{{item.bank_feed_item !== null ? 'BANK-FEED' : 'SCHEME-BALANCE'}}</td>
                    </template>
                    <!-- Total Amount -->
                    <template v-slot:[`item.transaction_amount`]="{ item }">
                        <td>R {{item.transaction_amount}}</td>
                    </template>
                    <!-- Allocation Amount -->
                    <template v-slot:[`item.allocation_amount`]="{ item }">
                        <td>R {{item.allocation_amount}}</td>
                    </template>
                    <!-- Payment Method -->
                    <template v-slot:[`item.payment_method`]="{ item }">
                        <td>{{item.payment_method.name}}</td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Policies Sub Tab -->
                    <template>
                        <v-tab key="policies_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Policies
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Transactions History Sub Tab -->
                    <template>
                        <v-tab key="transactions_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Transactions History
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 2 && selectedSchemeAllocation !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Scheme-Allocation Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteSchemeDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Scheme-Allocation Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <!-- Scheme transaction -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedSchemeAllocation !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddPremiumPaymentEventDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Capture Payment Event</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedSchemeAllocation !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshSchemeTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Payment Events</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Policies Sub-Tab Content -->
                    <v-tab-item key="policies_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="policies_details_headers"
                            :items="scheme_policies"
                            item-key="_id"
                            :single-select="true"
                            @click:row="tobeDefined"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Policy -->
                            <template v-slot:[`item.policy`]="{ item }">
                                <!-- <td>{{item.policy.policy_number}} ({{ item.policy.principal_member.name + ' ' + item.principal_member.surname }})</td> -->
                                <td>{{item.policy.policy_number}} ({{ item.policy.principal_member.name }} {{ item.policy.principal_member.surname }} - {{ item.policy.principal_member.id_number }})</td>
                            </template>
                            <!-- Premium Amount -->
                            <template v-slot:[`item.premium_amount`]="{ item }">
                                <td>R {{item.premium_amount}}</td>
                            </template>
                            <!-- Scheme Contribution Amount -->
                            <template v-slot:[`item.scheme_contribution_amount`]="{ item }">
                                <td>R {{item.scheme_contribution_amount}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Transactions History Sub-Tab Content -->
                    <v-tab-item key="transactions_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :loading="transactions_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display Formated transaction date -->
                            <template v-slot:[`item.transaction_date`]="{ item }">
                                <td>{{item.transaction_date !== null ? item.transaction_date.split('T')[0] : ''}}</td>
                            </template>
                            <!-- Display Formated created date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                            </template>
                            <!-- Display Formated amount -->
                            <template v-slot:[`item.amount`]="{ item }">
                                <td>R {{item.amount}}</td>
                            </template>
                            <!-- Display Formated balance -->
                            <template v-slot:[`item.balance`]="{ item }">
                                <td>R {{item.balance}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="scheme_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <OnboardingDialog
            :dialog="open_onboarding_dialog"
        />

        <!-- Document Dialogs -->
        <AddSchemeDocumentDialog
            v-if="selectedSchemeAllocation !== null"
            :dialog="open_add_document_dialog"
            :scheme="selectedSchemeAllocation"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_scheme_document_dialog"
            :document="selectedDocument"
            param_path="scheme_allocation"
            :param_id="selectedSchemeAllocation._id"
        />
        <DeleteSchemeDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_delete_scheme_document_dialog"
            :scheme="selectedSchemeAllocation"
            :document="selectedDocument"
        />

        <!-- Transaction History -->
        <AddSchemePremiumPaymentDialog
            v-if="selectedSchemeAllocation !== null"
            :dialog="open_add_premium_payment_dialog"
            :scheme="selectedSchemeAllocation"
        />

        <SearchSchemesDialog
            :dialog="open_search_schemes_dialog"
            @CLOSE_SCHEME_SEARCH_DIALOG="closeSearchSchemesDialog()"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import OnboardingDialog from './dialogs/add_scheme_allocation';


import AddSchemeDocumentDialog from './dialogs/add_scheme_documents';
import DeleteSchemeDocumentDialog from './dialogs/delete_scheme_document';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';

import SearchSchemesDialog from './dialogs/search_scheme';

import AddSchemePremiumPaymentDialog from './dialogs/add_scheme_premium_payment.vue';

import { emitAlert, emitSuccess } from "../../utils/api";
import { eventBus } from "../../main";

import { businessProcessSchemeAllocation } from "./gql/mutations";
import { getSchemeAllocations, getFinanceTransactionsList } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        OnboardingDialog,
        SearchSchemesDialog,

        AddSchemeDocumentDialog,
        DeleteSchemeDocumentDialog,
        ViewDocumentDialog,

        AddSchemePremiumPaymentDialog
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedSchemeAllocation: null,
        selectedRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        selectedSchemeAllocationCopy: {
            name:  null,
        },
        

        scheme_allocation_details_headers: [
            { text: 'Allocation Number', value: 'allocation_number' },
            { text: 'Date', value: 'transaction_date' },
            { text: 'Scheme', value: 'scheme' },
            { text: 'Available Amount', value: 'transaction_amount' },
            { text: 'Allocation Amount', value: 'allocation_amount' },
            { text: 'Payment Method', value: 'payment_method' },
            { text: 'Source', value: 'bank_feed_item' },
            { text: 'Status', value: 'status' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Created Date', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'System Reference', value: 'reference' },
            { text: 'Customer Reference', value: 'additional_reference' },{ text: 'Reference', value: 'reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
        ],
        policies_details_headers: [
            { text: 'Policy Number', value: 'policy' },
            { text: 'Premium Amount', value: 'premium_amount' },
            { text: 'Scheme Contribution', value: 'scheme_contribution_amount' },
        ],
        
        // Table data models
        scheme_documents: [],
        transactions: [],
        scheme_policies: [],
        

        // Dialogs
        
        scheme_allocations: [],
        open_onboarding_dialog: false,
        open_search_schemes_dialog: false,

        // Documents dialogs
        open_add_document_dialog: false,
        open_delete_scheme_document_dialog: false,
        open_view_scheme_document_dialog: false,
        // Premium
        open_add_premium_payment_dialog: false,

        open_view_scheme_change_history_dialog: false,
        
        data_table_loading: false,

        transactions_loading: false,


        page: 1,
        limit: 50,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openOnboardingDialog() {
            this.open_onboarding_dialog = true;
        },
        openSearchSchemesDialog() {
            this.open_search_schemes_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openDeleteSchemeDocumentDialog() {
            this.open_delete_scheme_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_scheme_document_dialog = true;
        },
        // openViewSchemeChangeHistory() {
        //     this.open_view_scheme_change_history_dialog = true;
        // },
        openAddPremiumPaymentEventDialog() {
            this.open_add_premium_payment_dialog = true;
        },

        resetSchemeEditForm(updatedScheme) {
            if (
                updatedScheme.name !== this.selectedSchemeAllocation.name
            ) {
                this.selectedSchemeAllocationCopy = Object.assign({}, this.selectedSchemeAllocation);
            }
        },
        resetSchemeLoanPremiumAllocationEditForm() {
            // this.selectedSchemeAllocationCopy = Object.assign({}, this.selectedSchemeAllocation);
            this.selectedSchemeAllocationCopy.loan_premium_allocation_priority.initiation_fees = this.selectedSchemeAllocation.loan_premium_allocation_priority.initiation_fees;
            this.selectedSchemeAllocationCopy.loan_premium_allocation_priority.interest = this.selectedSchemeAllocation.loan_premium_allocation_priority.interest;
            this.selectedSchemeAllocationCopy.loan_premium_allocation_priority.monthly_admin_fees = this.selectedSchemeAllocation.loan_premium_allocation_priority.monthly_admin_fees;
            this.selectedSchemeAllocationCopy.loan_premium_allocation_priority.principal = this.selectedSchemeAllocation.loan_premium_allocation_priority.principal;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeOnboardingDialog(new_scheme) {
            if (new_scheme !== null) {
                this.scheme_allocations.push(new_scheme);
            }
            this.open_onboarding_dialog = false;
        },

        updateUITableModel(new_scheme) {
            if (new_scheme !== null) {
                const obj_index = this.scheme_allocations.map(obj => { return obj._id; }).indexOf(new_scheme._id)
                this.scheme_allocations[obj_index] = new_scheme;

                 // Deep copy scheme-allocations for refreshing main data table
                const temp_arr = Array.from(this.scheme_allocations);
                this.scheme_allocations = temp_arr;

                // Also update the sub-tabs data
                this.scheme_documents = new_scheme.documents.filter(doc => { return !doc.deleted });
                this.scheme_policies = new_scheme.policies;

                // Reset data-table items
                this.selectedSchemeAllocation = new_scheme;
                // this.unselectSubTabRows();
            }
        },
        closeAddSchemeDocumentsDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_add_document_dialog = false;
        },
        closeDeleteSchemeDocumentDialog(new_scheme) {
            this.updateUITableModel(new_scheme);
            this.open_delete_scheme_document_dialog = false;

            this.selectedDocumentRow = null;
            this.selectedDocument = null;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_scheme_document_dialog = false;
        },
        closeSearchSchemesDialog() {
            this.open_search_schemes_dialog = false;
        },
        closeAddPremiumPaymentDialog(new_transaction) {
            if (new_transaction !== null) {
                // Prepend to transactions array
                this.transactions.unshift(new_transaction);

                // Prepare balance
                if (new_transaction.from._id === this.selectedSchemeAllocation._id) {
                    // new_transaction.balance = new_transaction.from_running_balance;
                    new_transaction.balance = new_transaction.balance_from;
                }

                if (new_transaction.to._id === this.selectedSchemeAllocation._id) {
                    // new_transaction.balance = new_transaction.to_running_balance;
                    new_transaction.balance = new_transaction.balance_to;
                }
            }
            this.open_add_premium_payment_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }
        },
        async selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedSchemeAllocation = item;
                // Update scheme documents model
                this.scheme_documents = item.documents.filter(doc => { return !doc.deleted });
                // Transactions
                this.transactions = [];
                // Policies
                this.scheme_policies = item.policies;

                // Set selected scheme copy
                this.selectedSchemeAllocationCopy = Object.assign({}, this.selectedSchemeAllocation);

                if (this.sub_tabs === 1) { // transactions-history
                    await this.refreshSchemeTransactions();
                }
            } else {
                this.selectedSchemeAllocation = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update scheme documents model
                this.scheme_documents = [];
                // Transactions
                this.transactions = [];
                // Policies
                this.scheme_policies = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        tobeDefined(){},

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getSchemeAllocations,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME_ALLOCATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.scheme_allocations = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    
        async refreshSchemeAllocations() {
            this.data_table_loading = true;
            this.scheme_allocations = [];

            this.page = 1;
            this.limit = 50;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getSchemeAllocations,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME_ALLOCATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.scheme_allocations = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshSchemeTransactions() {
            try {
                this.transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                account_id: this.selectedSchemeAllocation.scheme._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }


                this.transactions_loading = false;
                // this.transactions = res.data.getBusinessObject.business_objects;
                this.transactions = res.data.getBusinessObject.business_objects.filter(trans => {
                    return (
                        trans.additional_reference !== null &&
                        trans.additional_reference.split('scheme_allocation_number: SA-')[1] !== undefined &&
                        parseInt(trans.additional_reference.split('scheme_allocation_number: SA-')[1]) == this.selectedSchemeAllocation.allocation_number
                    )
                });

                // Prepare balance
                // let runningBalance = 0;
                // let runningAmount = 0;
                for (const trans of this.transactions) {
                    if (trans.from._id === this.selectedSchemeAllocation.scheme._id) {
                        trans.amount = -trans.amount;
                        trans.balance = trans.balance_from;
                    }

                    if (trans.to._id === this.selectedSchemeAllocation.scheme._id) {
                        trans.balance = trans.balance_to;
                    }
                }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async processSchemeAllocation() {
            this.data_table_loading = true;
            try {
                const payloadObj = {
                    OP_TYPE: 'PROCESS_ALLOCATION',
                    _id: this.selectedSchemeAllocation._id
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessSchemeAllocation,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessSchemeAllocation.errors === true) {
                    emitAlert(res.data.businessProcessSchemeAllocation.message);
                    this.data_table_loading = false;
                    return;
                }

                this.updateUITableModel(res.data.businessProcessSchemeAllocation.business_object);
                emitSuccess(res.data.businessProcessSchemeAllocation.message);
                
                this.data_table_loading = false;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessSchemeAllocation.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeOnboardingDialog);

        eventBus.$on('CLOSE_ADD_SCHEME_DOCUMENTS_DIALOG', this.closeAddSchemeDocumentsDialog);
        eventBus.$on('CLOSE_DELETE_SCHEME_DOCUMENT_DIALOG', this.closeDeleteSchemeDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);

        eventBus.$on('RESET_SCHEME_EDIT_FORM', this.resetSchemeEditForm);
        eventBus.$on('RESET_LOAN_PREMIUM_ALLOCATION_EDIT_FORM', this.resetSchemeLoanPremiumAllocationEditForm);

        eventBus.$on('CLOSE_ADD_SCHEME_PREMIUM_PAYMENT_DIALOG', this.closeAddPremiumPaymentDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getSchemeAllocations,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME_ALLOCATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.scheme_allocations = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        'sub_tabs': async function(val) {
            if (val === 1) { // transactions-history
                if (this.selectedSchemeAllocation !== null) {
                    await this.refreshSchemeTransactions();
                }
            }
        }
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>