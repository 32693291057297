<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="2" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchLoanDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for Loans</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshLoans()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedLoan !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditLoanDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="3">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="7" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        
                        <!-------------------------------------------------------------------------------->
                        <!----------------------------------LIFE CYCLE BUTTONS---------------------------->
                        <!-------------------------------------------------------------------------------->


                        <!-- PAYOUT LOAN -->
                        <v-tooltip
                            v-if="selectedLoan !== null && selectedLoan.status === LOAN_STATUSES.PENDING_PAYOUT"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="markLoanAsPaidOut()"
                                >
                                    <v-icon medium>mdi-cash-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Mark Loan as Paid-Out</span>
                        </v-tooltip>

                        <!-- CALCULATE LOAN BALANCE -->
                        <v-tooltip bottom v-if="selectedLoan !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="white black--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewDebitOrderStatus()"
                                >
                                    <v-icon medium>mdi-wallet</v-icon> Check Balance
                                </v-btn>
                            </template>
                            <span>Calculate Loan Balance</span>
                        </v-tooltip>
                        <!-- CALCULATE LOAN EVENTS-STREAM -->
                        <!-- <v-tooltip bottom v-if="selectedLoan !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="purple white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewEventStream()"
                                >
                                    <v-icon medium>mdi-timeline-clock</v-icon> View Loan Events
                                </v-btn>
                            </template>
                            <span>View Loan Events</span>
                        </v-tooltip> -->

                        <!-- VALIDATE DRAFTED LOAN -->
                        <!-- <v-tooltip bottom v-if="selectedLoan !== null && selectedLoan.status === LOAN_STATUSES.DRAFT">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="white black--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openValidateDraftedLoanDialog()"
                                >
                                    <v-icon medium>check</v-icon>
                                </v-btn>
                            </template>
                            <span>Validate Drafted Loan</span>
                        </v-tooltip> -->

                        <!-- GET DEBIT ORDER STATUS -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                (
                                    selectedLoan.status === LOAN_STATUSES.ACTIVE &&
                                    selectedLoan.repayment_info.payment_method === 'DEBIT ORDER'
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber primary--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewDebitOrderStatus()"
                                >
                                    <v-icon medium>mdi-cash-100</v-icon>
                                </v-btn>
                            </template>
                            <span>Get Debit Order Status</span>
                        </v-tooltip> -->

                        <!-- GENERATE LETTER OF DEMAND -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                (
                                    selectedLoan.status === LOAN_STATUSES.STAGE_1_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_2_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE_ACTION_REQUIRED ||
                                    selectedLoan.status === LOAN_STATUSES.IMPAIRED
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewDebitOrderStatus()"
                                >
                                <v-icon large>mdi-scale-balance</v-icon> Generate Letter of Demand
                                </v-btn>
                            </template>
                            <span>Generate Letter of Demand</span>
                        </v-tooltip> -->

                        <!-- RUN ALLOCATION PROCESS FOR LOAN -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                isCentralUnderwriterAdminUser &&
                                (
                                    selectedLoan.status === LOAN_STATUSES.ACTIVE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_1_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_2_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE_ACTION_REQUIRED ||
                                    selectedLoan.status === LOAN_STATUSES.IMPAIRED
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="success white--text"
                                    class="mx-2"
                                    v-on="on"
                                    :disabled="data_table_loading === true"
                                    @click="runAllocationProcessForLoan()"
                                >
                                <v-icon medium>mdi-download</v-icon> Run Allocation Process
                                </v-btn>
                            </template>
                            <span>Run Allocation Process</span>
                        </v-tooltip>

                        <!-- Statement -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary white--text"
                                    class="mx-2"
                                    v-on="on"
                                    :disabled="data_table_loading === true"
                                    @click="runAllocationProcessForLoan()"
                                >
                                Loan Statement
                                </v-btn>
                            </template>
                            <span>Loan Statement</span>
                        </v-tooltip> -->

                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="loan_details_headers"
                    :items="loans"
                    item-key="loan_number"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated loan number -->
                    <template v-slot:item.loan_number="{ item }">
                        <td>L{{item.loan_number}}</td>
                    </template>
                    <!-- Display Formated effective date -->
                    <template v-slot:item.effective_date="{ item }">
                        <td>{{item.effective_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated capture date -->
                    <template v-slot:item.created="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated projected_end_date -->
                    <template v-slot:item.projected_end_date="{ item }">
                        <td v-if="item.projected_end_date !== null">{{item.projected_end_date.split('T')[0]}}</td>
                        <td v-else></td>
                    </template>
                    <!-- Display Formated last_transaction_date -->
                    <template v-slot:item.last_transaction_date="{ item }">
                        <td v-if="item.last_transaction_date !== null">{{item.last_transaction_date.split('T')[0]}}</td>
                        <td v-else></td>
                    </template>
                    <!-- Display Formated amount -->
                    <template v-slot:item.amount="{ item }">
                        <td>R {{formatNumber(item.amount) }}</td>
                    </template>
                    <!-- Display Formated premium -->
                    <template v-slot:item.premium="{ item }">
                        <td>R {{item.premium }}</td>
                    </template>
                    <!-- Display Formated entity -->
                    <template v-slot:item.entity="{ item }">
                        <td>
                            {{
                                item.entity !== null && item.entity !== undefined ? 
                                item.entity.name + ' ' + item.entity.surname + ' (' + item.entity.id_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated agent -->
                    <template v-slot:item.agent="{ item }">
                        <td>
                            {{
                                item.agent !== null && item.agent !== undefined ? 
                                item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated scheme -->
                    <template v-slot:item.scheme="{ item }">
                        <td>
                            {{
                                item.scheme !== null && item.scheme !== undefined ? 
                                item.scheme.name + ' (' + item.scheme.scheme_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated principal Amount -->
                    <template v-slot:item.pricing_input.principal_amount="{ item }">
                        <td>
                            {{
                                item.pricing_input.principal_amount !== null && item.pricing_input.principal_amount !== undefined ? 
                                'R '+item.pricing_input.principal_amount :
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated Loan Term -->
                    <template v-slot:item.pricing_input.loan_term="{ item }">
                        <td>
                            {{
                                item.pricing_input.loan_term !== null && item.pricing_input.loan_term !== undefined ? 
                                item.pricing_input.loan_term + ' M' : ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated Loan Type -->
                    <template v-slot:item.pricing_input.loan_type="{ item }">
                        <td v-if="item.pricing_input.loan_type === 1">Unsecured Credit</td>
                        <td v-else-if="item.pricing_input.loan_type === 2">Asset Financing</td>
                        <td v-else-if="item.pricing_input.loan_type === 3">Revolving Credit</td>
                        <td v-else-if="item.pricing_input.loan_type === 4">Developmental Credit</td>
                        <td v-else-if="item.pricing_input.loan_type === 5">Mortage Agreement</td>
                        <td v-else>Unknown</td>
                    </template>
                    <!-- <template v-slot:item.pricing_input.specialized_landing="{ item }">
                        <td v-if="item.pricing_input.specialized_landing === true"><v-icon color="success">mdi-check</v-icon></td>
                        <td v-else><v-icon color="red">mdi-close</v-icon></td>
                    </template> -->
                    <!-- User who captured the details -->
                    <template v-slot:item.created_by="{ item }" >
                        <td v-if="item.created_by !== null">
                            <span v-if="item.created_by.entity !== null">
                                {{item.created_by.entity.name}} {{item.created_by.entity.surname}}
                            </span>
                            <span v-else>N/A</span>
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Amortization Schedule Sub Tab -->
                    <template>
                        <v-tab key="amortization_schedule_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Amortization Schedule
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Pricing-Parameters Sub Tab -->
                    <template>
                        <v-tab key="pricing_parameters_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Pricing Parameters
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Entity-Banking Details Sub Tab -->
                    <template>
                        <v-tab key="entity_banking_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Entity Banking
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Transactions History Sub Tab -->
                    <template>
                        <v-tab key="transactions_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Transactions History
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Installment Schedule Sub Tab -->
                    <template>
                        <v-tab key="installment_schedule_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Installment Schedule
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Operational Excellence Cases Sub Tab -->
                    <template>
                        <v-tab key="cases_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Cases
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 1 &&
                                selectedDocument !== null &&
                                selectedLoan !== null
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <!-- Transactions History Sub-Tab-Buttons -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 &&
                                selectedLoan !== null &&
                                (
                                    selectedLoan.status === LOAN_STATUSES.ACTIVE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_1_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_2_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE_ACTION_REQUIRED ||
                                    selectedLoan.status === LOAN_STATUSES.IMPAIRED
                                )
                        
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddPremiumPaymentEventDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Capture Payment Event</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 &&
                                selectedLoan !== null &&
                                (
                                    selectedLoan.status === LOAN_STATUSES.ACTIVE ||
                                    selectedLoan.status === LOAN_STATUSES.REPAID ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_1_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_2_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE_ACTION_REQUIRED ||
                                    selectedLoan.status === LOAN_STATUSES.IMPAIRED
                                )
                        
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshLoanTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Payment Events</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 &&
                                isCentralUnderwriterAdminUser &&
                                selectedTransaction !== null &&
                                selectedTransaction.to._id === selectedLoan._id
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 20px;"
                                    color="purple white--text"
                                    small
                                    @click="associateTransactionWithBankFeed()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>Associate With Bank Feed</span>
                        </v-tooltip>
                        <!-- Reverse Transaction -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 &&
                                isCentralUnderwriterAdminUser &&
                                selectedLoan !== null &&
                                selectedTransaction !== null &
                                (
                                    selectedLoan.status === LOAN_STATUSES.ACTIVE ||
                                    selectedLoan.status === LOAN_STATUSES.REPAID ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_1_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_2_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE ||
                                    selectedLoan.status === LOAN_STATUSES.STAGE_3_IMPAIRABLE_ACTION_REQUIRED ||
                                    selectedLoan.status === LOAN_STATUSES.IMPAIRED
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 20px;"
                                    color="red white--text"
                                    small
                                    @click="openReverseTransactionDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-arrow-u-left-top</v-icon> Reverse Transaction
                                </v-btn>
                            </template>
                            <span>Reverse Transaction</span>
                        </v-tooltip>
                        <!-- Allocate Transaction -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 &&
                                isCentralUnderwriterAdminUser &&
                                selectedLoan !== null &&
                                selectedTransaction !== null &&
                                selectedTransaction.to._id === selectedLoan._id
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 20px;"
                                    color="primary white--text"
                                    small
                                    @click="openLoanTransactionAllocationDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-download</v-icon> Allocate Transaction
                                </v-btn>
                            </template>
                            <span>Allocate Transaction</span>
                        </v-tooltip> -->

                        <!-- View debit order -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedLoan !== null &&
                                selectedLoan.repayment_info.payment_method === 'DEBIT ORDER' &&
                                selectedInstallmentSchedule !== null &&
                                selectedInstallmentSchedule.softy_comp_collections.length > 0
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 20px;"
                                    color="amber black--text"
                                    small
                                    @click="openViewScheduleDebitOrderDetailsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-cash-100</v-icon> View All Debit Orders
                                </v-btn>
                            </template>
                            <span>View All Debit Orders</span>
                        </v-tooltip>
                        <!-- Resubmit debit order -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedLoan !== null &&
                                selectedLoan.repayment_info.payment_method === 'DEBIT ORDER' &&
                                (
                                    isCentralUnderwriterAdminUser ||
                                    hasAbilityToSubmitDebitOrder
                                ) &&
                                selectedLoan.status !== LOAN_STATUSES.REPAID &&
                                selectedInstallmentSchedule !== null &&
                                selectedInstallmentSchedule.installment_status !== 'FULLY-COLLECTED' &&
                                selectedInstallmentSchedule.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length === 0
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 20px;"
                                    color="success white--text"
                                    small
                                    @click="openSubmitDebitOrderDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-cash-100</v-icon> Submit New Debit Order
                                </v-btn>
                            </template>
                            <span>Submit New Debit Order</span>
                        </v-tooltip>
                        <!-- Reverse Allocation -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                isCentralUnderwriterAdminUser &&
                                selectedLoan !== null &&
                                selectedInstallmentSchedule !== null &&
                                selectedInstallmentSchedule.premium_total_collected > 0
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 20px;"
                                    color="red white--text"
                                    small
                                    @click="openReverseAllocationDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-arrow-u-left-top</v-icon> Reverse Allocation
                                </v-btn>
                            </template>
                            <span>Reverse Allocation</span>
                        </v-tooltip> -->

                        <!-- Operational Excellence Sub-Tab Buttons -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 6 &&
                                selectedLoan !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshLoanCases()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Loan Cases</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 6 &&
                                selectedCase !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber primary--text"
                                    small
                                    v-on="on"
                                    @click="openViewCaseDetailsDialog()"
                                >
                                    <v-icon>mdi-eye-circle</v-icon> View Case
                                </v-btn>
                            </template>
                            <span>View Case Details</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Amortization Schedule Sub-Tab Content -->
                    <v-tab-item key="amortization_schedule_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="amortization_schedule_headers"
                            :items="amortization_schedule"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :loading="amortization_schedule_loading"
                            :disable-pagination="true"
                        >
                            <template v-slot:item.term_start="{ item }">
                                <td>Month  {{ item.term_start }}</td>
                            </template>
                            <template v-slot:item.term_end="{ item }">
                                <td>Month  {{ item.term_end }}</td>
                            </template>
                            <template v-slot:item.annual_interest_amortization_min_month_term="{ item }">
                                <td>{{ item.annual_interest_amortization_min_month_term }} Months</td>
                            </template>
                            <template v-slot:item.principal_required="{ item }">
                                <td v-if="item.principal_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <template v-slot:item.initiation_fee_premium_required="{ item }">
                                <td v-if="item.initiation_fee_premium_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <template v-slot:item.admin_fee_required="{ item }">
                                <td v-if="item.admin_fee_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <template v-slot:item.interest_required="{ item }">
                                <td v-if="item.interest_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="loan_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who created the details -->
                            <template v-slot:item.captured_by="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    <span v-if="item.captured_by.entity !== null">
                                        {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Pricing Parameters Sub-Tab Content -->
                    <v-tab-item key="pricing_parameters_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedLoan !== null">
                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Fees</h2></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.fees_config.monthly_admin_fee"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Monthly Admin Fee"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.fees_config.initiation_fee_principal_percentage"
                                            prefix="%"
                                            color="secondary"
                                            label="Init Fee Percentage"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-checkbox
                                            v-model="selectedLoan.pricing_input.fees_config.initiation_fee_paid_at_inception"
                                            color="secondary"
                                            label="Init Fee Paid At Inception"
                                            disabled
                                            required
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Interest</h2></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.interest_config.annual_interest_rate"
                                            prefix="%"
                                            color="secondary"
                                            label="Annual Interest Rate"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.interest_config.annual_interest_rate_monthly"
                                            prefix="%"
                                            color="secondary"
                                            label="Annual Interest Rate (Monthly)"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Entity-Banking Details -->
                    <v-tab-item key="entity_banking_details_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedLoan !== null">
                                <v-row v-if="selectedLoan.repayment_info.payment_method === 'DEBIT ORDER'">
                                    <v-col cols="12" sm="12"><h2>Collection Details</h2></v-col>
                                    <v-col cols="12" sm="12">
                                        <v-radio-group v-model="selectedLoan.collection_banking" disabled>
                                            <v-radio
                                                v-for="bankObj in selectedLoan.entity.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                :key="bankObj._id"
                                                :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                :value="bankObj._id"
                                                color="secondary"
                                            ></v-radio>
                                        </v-radio-group>
                                        <h1
                                            style="color:red"
                                            v-if="selectedLoan.entity.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                        >
                                            No Banking Details Captured
                                        </h1>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Payout Details</h2></v-col>
                                    <v-col cols="12" sm="12">
                                        <v-radio-group v-model="selectedLoan.payout_banking" disabled>
                                            <v-radio
                                                v-for="bankObj in selectedLoan.entity.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                :key="bankObj._id"
                                                :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                :value="bankObj._id"
                                                color="secondary"
                                            ></v-radio>
                                        </v-radio-group>
                                        <h1
                                            style="color:red"
                                            v-if="selectedLoan.entity.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                        >
                                            No Banking Details Captured
                                        </h1>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- Transactions History Sub-Tab Content -->
                    <v-tab-item key="transactions_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectTransactionTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :loading="transactions_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.transaction_date="{ item }">
                                <td>{{item.transaction_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated created date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                            </template>
                            <!-- Display Formated amount -->
                            <template v-slot:item.amount="{ item }">
                                <td>R {{item.amount}}</td>
                            </template>
                            <!-- Display Formated balance -->
                            <template v-slot:item.balance="{ item }">
                                <td>R {{item.balance}}</td>
                            </template>
                            <!-- Display Formated Bank Feed -->
                            <template v-slot:item.bank_feed="{ item }">
                                <td v-if="item.to._id === selectedLoan._id">{{item.bank_feed === null ? 'NO' : 'YES'}}</td>
                                <td v-else></td>
                            </template>
                            <!-- User who created the details -->
                            <template v-slot:item.created_by="{ item }" >
                                <td v-if="item.created_by !== null">
                                    <span v-if="item.created_by.entity !== null">
                                        {{item.created_by.entity.name}} {{item.created_by.entity.surname}}
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Installment Schedule Sub-Tab Content -->
                    <v-tab-item key="installment_schedule_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="installment_schedule_headers"
                            :items="installment_schedule"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectInstallmentScheduleTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated deadline_date: false, -->
                            <template v-slot:item.deadline_date="{ item }">
                                <td>{{item.deadline_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated installment_item_carried_over: false, -->
                            <!-- <template v-slot:item.installment_item_carried_over="{ item }">
                                <td>
                                    <span v-if="item.installment_item_carried_over === true">YES</span>
                                    <span v-else>NO</span>
                                </td>
                            </template> -->

                            <!-- Display Formated softy_comp_collections: false, -->
                            <template v-slot:item.softy_comp_collections="{ item }">
                                <td>
                                    <v-chip
                                        v-if="item.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length > 0"
                                        color="success"
                                        dark
                                    >
                                        {{ item.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length }}
                                    </v-chip> 
                                    <v-chip
                                        v-else
                                        color="red"
                                        dark
                                    >
                                        {{ item.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length }}
                                    </v-chip> 
                                    / 
                                    <v-chip
                                        color="grey"
                                        dark
                                    >
                                        {{ item.softy_comp_collections.length }}
                                    </v-chip>
                                </td>
                            </template>

                            <!-- Display Formated premium_principal -->
                            <template v-slot:item.premium_principal="{ item }">
                                <td>
                                    R {{item.premium_principal}} / <b>{{item.premium_principal_target}}</b>

                                    <span v-if="item.premium_principal_target > 0">
                                        <span v-if="item.premium_principal === item.premium_principal_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_principal}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_principal_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_principal === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_principal_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>
                            <!-- Display Formated premium_admin_fee -->
                            <template v-slot:item.premium_admin_fee="{ item }">
                                <td>
                                    R {{item.premium_admin_fee}} / <b>{{item.premium_admin_fee_target}}</b>

                                    <span v-if="item.premium_admin_fee_target > 0">
                                        <span v-if="item.premium_admin_fee === item.premium_admin_fee_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_admin_fee}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_admin_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_admin_fee === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_admin_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>
                            <!-- Display Formated premium_initiation_fee -->
                            <template v-slot:item.premium_initiation_fee="{ item }">
                                <td>
                                    R {{item.premium_initiation_fee}} / <b>{{item.premium_initiation_fee_target}}</b>

                                    <span v-if="item.premium_initiation_fee_target > 0">
                                        <span v-if="item.premium_initiation_fee === item.premium_initiation_fee_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_initiation_fee}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_initiation_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_initiation_fee === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_initiation_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>
                            <!-- Display Formated premium_interest -->
                            <template v-slot:item.premium_interest="{ item }">
                                <td>
                                    R {{item.premium_interest}} / <b>{{item.premium_interest_target}}</b>

                                    <span v-if="item.premium_interest_target > 0">
                                        <span v-if="item.premium_interest === item.premium_interest_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_interest}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_interest_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_interest === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_interest_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>

                            <!-- Total theoretical -->
                            <template v-slot:item.premium_total_theoretical="{ item }">
                                <v-chip
                                    color="green"
                                    dark
                                >
                                    R {{ item.premium_total_theoretical }}
                                </v-chip>
                            </template>
                            <!-- Total collected -->
                            <template v-slot:item.premium_total_collected="{ item }">
                                <v-chip
                                    :color="item.premium_total_theoretical === item.premium_total_collected ? 'green' : 'red'"
                                    dark
                                >
                                    R {{ item.premium_total_collected }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Operational Excellence Cases Sub-Tab Content -->
                    <v-tab-item key="cases_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="case_details_headers"
                            :items="loan_cases"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectCaseTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :loading="loan_cases_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display Formated case number -->
                            <template v-slot:item.case_number="{ item }">
                                <td>CS-{{item.case_number}}</td>
                            </template>
                            <!-- Display Formated next action date -->
                            <template v-slot:item.next_action_date="{ item }">
                                <td>{{item.next_action_date === null ? '' : item.next_action_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated close date -->
                            <template v-slot:item.close_date="{ item }">
                                <td>{{item.close_date === null ? '' : item.close_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated capture date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <SearchLoansDialog
            :dialog="open_search_loans_dialog"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_loan_document_dialog"
            :document="selectedDocument"
            param_path="loan"
            :param_id="selectedLoan._id"
        />
        <AssociateTransactionToBankFeedDialog
            v-if="selectedTransaction !== null"
            :dialog="open_transaction_bank_feed_association_dialog"
            :transaction="selectedTransaction"
        />
        <AddLoanPremiumPaymentDialog
            v-if="selectedLoan !== null"
            :dialog="open_add_premium_payment_dialog"
            :loan="selectedLoan"
        />
        <ViewDebtStatusDialog
            v-if="selectedLoan !== null"
            :dialog="open_view_debt_status_dialog"
            :loan="selectedLoan"
        />
        <ViewEventsStreamDialog
            v-if="selectedLoan !== null"
            :dialog="open_view_events_stream_dialog"
            :loan="selectedLoan"
        />
        <EditBasicLoanDialog
            v-if="selectedLoan !== null"
            :dialog="open_edit_loan_dialog"
            :loan_original="selectedLoan"
            :loan_copy="selectedLoanCopy"
        />
        <ViewLoanCaseDetailsDialog
            v-if="selectedCase !== null"
            :dialog="open_view_case_dialog"
            :case_original="selectedCase"
        />
        <ViewScheduleItemDebitOrderDetailsDialog
            v-if="selectedInstallmentSchedule !== null"
            :dialog="open_view_schedule_debit_order_details_dialog"
            :loan="selectedLoan"
            :installment_item="selectedInstallmentSchedule"
        />
        <ReverseTransactionDialog
            v-if="selectedTransaction !== null"
            :dialog="open_reverse_transaction_dialog"
            :loan="selectedLoan"
            :transaction="selectedTransaction"
        />
        <LoanTransactionAllocationDialog
            v-if="selectedTransaction !== null"
            :dialog="open_loan_transaction_allocation_dialog"
            :loan="selectedLoan"
            :transaction="selectedTransaction"
        />
        <ResubmitDebitOrderDialog
            v-if="selectedInstallmentSchedule !== null"
            :dialog="open_submit_debit_order_dialog"
            :loan="selectedLoan"
            :installment_schedule_item="selectedInstallmentSchedule"
        />

        <!-- Life Cycle Management -->
        <LoanPayoutDialog
            v-if="selectedLoan !== null"
            :dialog="open_loan_payout_dialog"
            :loan="selectedLoan"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import SearchLoansDialog from './dialogs/search_loans';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';
import AddLoanPremiumPaymentDialog from './dialogs/add_loan_premium_payment.vue';
import AssociateTransactionToBankFeedDialog from './dialogs/associate_transaction_to_bank_feed.vue';
import ViewDebtStatusDialog from './dialogs/view_debt_status.vue';
import ViewEventsStreamDialog from './dialogs/view_events_stream.vue';
import EditBasicLoanDialog from './dialogs/edit_basic_loan_details.vue';
import ViewLoanCaseDetailsDialog from './dialogs/view_loan_case_details.vue';
import ViewScheduleItemDebitOrderDetailsDialog from './dialogs/view_schedule_debit_order_details.vue';
import ReverseTransactionDialog from './dialogs/reverse_transaction.vue';
import LoanTransactionAllocationDialog from './dialogs/allocate_transaction.vue';
import ResubmitDebitOrderDialog from './dialogs/resubmit_debit_order.vue';

// // Life cycle managers
import LoanPayoutDialog from './dialogs/life_cycle/pending_payout/payout_loan.vue';

import { eventBus } from "../../main";
import { emitAlert, emitSuccess } from "../../utils/api";
import { LOAN_STATUSES, BUSINESS_PARTNER_TYPES } from "../../../config";


import converter from "../../mixins/converter";

import { getLoans, getFinanceTransactionsList, getOperationalExcellenceCases } from "./gql/queries";
import { businessProcessLoan } from "./gql/mutations";
import { store } from "../../../store";

export default {
    mixins: [converter],
    components: {
        Multipane,
        MultipaneResizer,
        SearchLoansDialog,
        ViewDocumentDialog,
        AddLoanPremiumPaymentDialog,
        AssociateTransactionToBankFeedDialog,
        ViewDebtStatusDialog,
        ViewEventsStreamDialog,
        EditBasicLoanDialog,
        ViewLoanCaseDetailsDialog,
        ViewScheduleItemDebitOrderDetailsDialog,
        ReverseTransactionDialog,
        LoanTransactionAllocationDialog,
        ResubmitDebitOrderDialog,

        // Loan Life Cycle Dialogs
        LoanPayoutDialog,
    },

    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        hasAbilityToSubmitDebitOrder() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'debit order resubmit'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedLoan: null,
        selectedRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        // documents sub-tab data-table row
        selectedTransaction: null,
        selectedTransactionRow: null,

        selectedCase: null,
        selectedCaseRow: null,

        selectedInstallmentSchedule: null,
        selectedInstallmentScheduleRow: null,

        loan_details_headers: [
            { text: 'Loan Number', value: 'loan_number' },
            // { text: 'Loan Type', value: 'pricing_input.loan_type' },
            { text: 'Loan Entity', value: 'entity' },
            { text: 'Agent', value: 'agent' },
            { text: 'Scheme', value: 'scheme' },
            { text: 'Monthly Repayment Date', value: 'repayment_info.elected_repayment_time_point', width: '4%' },
            { text: 'Payment Method', value: 'repayment_info.payment_method', width: '6%' },
            { text: 'Amount', value: 'pricing_input.principal_amount', width: '4%' },
            { text: 'Premium', value: 'premium', width: '5%' },
            { text: 'Loan Term', value: 'pricing_input.loan_term' },
            // { text: 'Specialized Lending', value: 'pricing_input.specialized_landing' },
            { text: 'Bank Name', value: 'bank_name' },
            { text: 'Status', value: 'status', width: '6%' },
            // { text: 'Bank Statement Status', value: 'bank_statement_status' },
            { text: 'Effective Date', value: 'effective_date', width: '6%' },
            { text: 'Projected Date', value: 'projected_end_date' },
            { text: 'Last Transaction Date', value: 'last_transaction_date' },
            { text: 'Date Captured', value: 'created', width: '6%' },
            { text: 'Created By', value: 'created_by' },
        ],
        amortization_schedule_headers: [
            { text: 'Term Start', value: 'term_start' },
            { text: 'Term End', value: 'term_end' },
            { text: 'Annual Interest Amortization Min Month Term', value: 'annual_interest_amortization_min_month_term' },

            { text: 'Principal Required', value: 'principal_required' },
            { text: 'Init Fee Prem Required', value: 'initiation_fee_premium_required' },
            { text: 'Admin Fee Required', value: 'admin_fee_required' },
            { text: 'Interest Required', value: 'interest_required' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'System Reference', value: 'reference' },
            { text: 'Customer Reference', value: 'additional_reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
            { text: 'Bank Feed Association', value: 'bank_feed' },
            { text: 'Captured By', value: 'created_by' },
        ],
        installment_schedule_headers: [
            { text: 'Installment Number', value: 'installment_number' },
            { text: 'Deadline Date', value: 'deadline_date' },
            { text: 'Installment Status', value: 'installment_status' },
            { text: 'Active / All [Debit Orders]', value: 'softy_comp_collections' },

            { text: 'Principal', value: 'premium_principal' },
            { text: 'Admin Fee', value: 'premium_admin_fee' },
            { text: 'Init Fee', value: 'premium_initiation_fee' },
            { text: 'Interest', value: 'premium_interest' },

            { text: 'Total Expected', value: 'premium_total_theoretical' },
            { text: 'Total Collected', value: 'premium_total_collected' },
        ],
        case_details_headers: [
            { text: 'Case Number', value: 'case_number' },
            { text: 'Month', value: 'calendar_month.name' },
            { text: 'Status', value: 'status' },
            { text: 'Category', value: 'category' },
            { text: 'Next Action Date', value: 'next_action_date' },
            { text: 'Close Date', value: 'close_date' },
            { text: 'Date Created', value: 'created' },
        ],
        
        // Table data models
        amortization_schedule: [],
        loan_documents: [],
        transactions: [],
        loan_cases: [],
        installment_schedule: [],

        // Dialogs
        
        loans: [],
        open_search_loans_dialog: false,
        open_view_loan_document_dialog: false,
        open_add_premium_payment_dialog: false,
        open_transaction_bank_feed_association_dialog: false,
        open_view_debt_status_dialog: false,
        open_view_events_stream_dialog: false,
        open_edit_loan_dialog: false,
        open_view_case_dialog: false,
        open_view_schedule_debit_order_details_dialog: false,
        open_reverse_transaction_dialog: false,
        open_loan_transaction_allocation_dialog: false,
        open_submit_debit_order_dialog: false,

        // Life cycle management
        open_loan_payout_dialog: false,

        data_table_loading: false,
        SEARCH_MODE: false,
        page: 1,
        limit: 50,

        LOAN_STATUSES: LOAN_STATUSES,
        
        selectedLoanCopy: null,

        amortization_schedule_loading: false,
        transactions_loading: false,
        loan_cases_loading: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openSearchLoanDialog() {
            // this.unselectSelectedRow();
            this.open_search_loans_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_loan_document_dialog = true;
        },
        openAddPremiumPaymentEventDialog() {
            this.open_add_premium_payment_dialog = true;
        },
        associateTransactionWithBankFeed() {
            this.open_transaction_bank_feed_association_dialog = true;
        },
        openViewDebitOrderStatus() {
            this.open_view_debt_status_dialog = true;
        },
        openViewEventStream() {
            this.open_view_events_stream_dialog = true;
        },
        openEditLoanDialog() {
            this.open_edit_loan_dialog = true;
        },
        openViewCaseDetailsDialog() {
            this.open_view_case_dialog = true;
        },
        openViewScheduleDebitOrderDetailsDialog() {
            this.open_view_schedule_debit_order_details_dialog = true;
        },
        openReverseTransactionDialog() {
            this.open_reverse_transaction_dialog = true;
        },
        openLoanTransactionAllocationDialog() {
            this.open_loan_transaction_allocation_dialog = true;
        },
        openSubmitDebitOrderDialog() {
            this.open_submit_debit_order_dialog = true;
        },

        resetLoanEditForm(updatedLoan) {
            if (
                parseFloat(updatedLoan.amount) !== this.selectedLoan.amount ||
                parseFloat(updatedLoan.installments) !== this.selectedLoan.installments ||
                parseFloat(updatedLoan.elected_monthly_repayment_date) !== this.selectedLoan.elected_monthly_repayment_date ||
                updatedLoan.payment_method !== this.selectedLoan.payment_method ||

                updatedLoan.elected_monthly_repayment_date !== this.selectedLoan.elected_monthly_repayment_date
            ) {
                // this.selectedLoanCopy = Object.assign({}, this.selectedLoan);
                this.selectedLoanCopy = JSON.parse( JSON.stringify(this.selectedLoan) );
            }
        },
        async runAllocationProcessForLoan() {
            this.data_table_loading = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'RUN_ALLOCATION_PROCESS',
                    _id: this.selectedLoan._id,
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    this.data_table_loading = false;
                    return;
                }

                emitSuccess(res.data.businessProcessLoan.message);
                this.data_table_loading = false;

                // Update UI model
                this.updateUITableModel(res.data.businessProcessLoan.business_object);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
        //------------------------------------------------------------------------------------//
        //-------------------------------- LIFE CYCLE MANAGEMENT -----------------------------//
        //------------------------------------------------------------------------------------//

        // Draft
        markLoanAsPaidOut() {
            this.open_loan_payout_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_loan) {
            if (new_loan !== null) {
                const obj_index = this.loans.map(obj => { return obj._id; }).indexOf(new_loan._id)
                this.loans[obj_index] = new_loan;

                this.selectedLoan = new_loan;
                this.selectedLoanCopy = JSON.parse( JSON.stringify(new_loan) );

                 // Deep copy loans for refreshing main data table
                const temp_arr = Array.from(this.loans);
                this.loans = temp_arr;

                // Also update the documents tab
                this.loan_documents = new_loan.documents.filter(doc => { return !doc.deleted });
                this.amortization_schedule = new_loan.pricing_input.amortization_schedule_config;
                this.installment_schedule = new_loan.installment_schedule;
            }
        },
        closeEditLoanDialog(new_loan) {
            this.updateUITableModel(new_loan);
            this.open_edit_loan_dialog = false;
        },
        closeSearchLoansDialog(searchResults) {
            if (searchResults !== null) {
                this.loans = searchResults;
                this.SEARCH_MODE = true;

                // Update loan addresses model
                this.amortization_schedule = [];
                // Update loan documents model
                this.loan_documents = [];
            }
            this.open_search_loans_dialog = false;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_loan_document_dialog = false;
        },
        closeLoanPayoutDialog(canceled) {
            if (canceled === false) {
                this.selectedLoan = null;

                // Also update the documents tab
                this.loan_documents = [];
                this.amortization_schedule = [];

                this.refreshLoans();
                this.unselectSubTabRows();
            }

            this.open_loan_payout_dialog = false;
        },
        closeAddPremiumPaymentDialog(new_transaction) {
            if (new_transaction !== null) {
                // Prepend to transactions array
                this.transactions.unshift(new_transaction);

                // Prepare balance
                if (new_transaction.from._id === this.selectedLoan._id) {
                    new_transaction.balance = new_transaction.balance_from;
                }

                if (new_transaction.to._id === this.selectedLoan._id) {
                    new_transaction.balance = new_transaction.balance_to;
                }
            }
            this.open_add_premium_payment_dialog = false;
        },
        closeAsociateTransactionWithBankFeedDialog(new_transaction) {
            if (new_transaction !== null) {
                this.selectedTransaction = JSON.parse(JSON.stringify(new_transaction));
                this.refreshLoanTransactions();
            }
            this.open_transaction_bank_feed_association_dialog = false;
        },
        closeViewDebtStatus() {
            this.open_view_debt_status_dialog = false;
        },
        closeViewEventsStream() {
            this.open_view_events_stream_dialog = false;
        },
        closeViewCaseDialog() {
            this.open_view_case_dialog = false;
        },
        closeViewScheduleDebitOrderDetailsDialog(newLoan, newScheduleItem) {
            if (newLoan !== null && newScheduleItem !== null) {
                this.updateUITableModel(newLoan);
                this.selectedInstallmentSchedule = newScheduleItem;
            }

            this.open_view_schedule_debit_order_details_dialog = false;
        },
        async closeReverseLoanAllocationDialog(cancelled, newLoan) {
            if (cancelled === false) {
                this.updateUITableModel(newLoan);
                await this.refreshLoanTransactions();

                // Unselect currently selected transaction
                this.selectedTransaction = null;
                if (this.selectedTransactionRow !== null) {
                    this.selectedTransactionRow.select(false);
                }
            }
            this.open_reverse_transaction_dialog = false;
        },
        async closeLoanTransactionAllocationDialog(cancelled, newLoan) {
            if (cancelled === false) {
                this.updateUITableModel(newLoan);
                await this.refreshLoanTransactions();

                // Unselect currently selected transaction
                this.selectedTransaction = null;
                if (this.selectedTransactionRow !== null) {
                    this.selectedTransactionRow.select(false);
                }
            }
            this.open_loan_transaction_allocation_dialog = false;
        },
        closeSubmitDebitOrderDialog(newLoan, newScheduleItem) {
            if (newLoan !== null && newScheduleItem !== null) {
                this.updateUITableModel(newLoan);
                this.selectedInstallmentSchedule = newScheduleItem;
            }

            this.open_submit_debit_order_dialog = false;
        },


        // Row Selector Control
        unselectSubTabRows() {
            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }
            // Unselect transactions sub-tab row
            this.selectedTransaction = null;
            if (this.selectedTransactionRow !== null) {
                this.selectedTransactionRow.select(false);
            }
            // Unselect case sub-tab row
            this.selectedCase = null;
            if (this.selectedCaseRow !== null) {
                this.selectedCaseRow.select(false);
            }
            // Unselect installment-schedule sub-tab row
            this.selectedInstallmentSchedule = null;
            if (this.selectedInstallmentScheduleRow !== null) {
                this.selectedInstallmentScheduleRow.select(false);
            }
        },
        async selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            this.selectedLoanCopy = JSON.parse( JSON.stringify(item) );

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedLoan = item;
                // Update loan documents model
                this.loan_documents = item.documents.filter(doc => { return !doc.deleted });
                // Update amortization_schedule model
                this.amortization_schedule = item.pricing_input.amortization_schedule_config;
                // Update installment schedule
                this.installment_schedule = item.installment_schedule;
                
                this.transactions = [];
                this.loan_cases = [];
                // Create loan copy
                // this.selectedLoanCopy = JSON.parse( JSON.stringify(this.selectedLoan) );

                if (this.sub_tabs === 4) { // transactions-history
                    await this.refreshLoanTransactions();
                }

                // this.selectedLoanCopy = Object.assign({}, this.selectedLoan);
                // console.log('ABA: ', this.selectedLoan);
            } else {
                this.selectedLoan = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update loan addresses model
                this.amortization_schedule = [];
                // Update loan documents model
                this.loan_documents = [];
                // Update installment schedule
                this.installment_schedule = [];
                this.transactions = [];
                this.loan_cases = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        selectTransactionTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedTransaction = item;
                this.selectedTransactionRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedTransaction = null;
                this.selectedTransactionRow = null;
            }
        },
        selectCaseTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedCase = item;
                this.selectedCaseRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedCase = null;
                this.selectedCaseRow = null;
            }
        },
        selectInstallmentScheduleTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedInstallmentSchedule = item;
                this.selectedInstallmentScheduleRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedInstallmentSchedule = null;
                this.selectedInstallmentScheduleRow = null;
            }
        },
        tobeDefined(){},

        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSubTabRows();
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshLoans();
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                // Update loan addresses model
                this.amortization_schedule = [];
                // Update loan documents model
                this.loan_documents = [];
                // Update transactions model
                this.transactions = [];
                // Update installment schedule
                this.installment_schedule = [];
                this.loan_cases = [];
            }
            this.selectedLoan = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;

            this.page += 1;
            // getLoans(this.page, this.limit).then(res => {
            //     this.data_table_loading = false;
            //     this.loans = this.loans.concat( res.data );
            // });

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getLoans,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'LOAN',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.loans = this.loans.concat( res.data.getBusinessObject.business_objects );
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshLoans() {
            this.data_table_loading = true;
            this.loans = [];

            this.page = 1; //, limit = 20;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getLoans,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'LOAN',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.loans = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },

        async refreshLoanTransactions() {
            try {
                this.transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 100
                                            },
                                            searchInput: {
                                                account_id: this.selectedLoan._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }


                this.transactions_loading = false;
                // this.transactions = res.data.getBusinessObject.business_objects;
                const transactionsTemp = res.data.getBusinessObject.business_objects.reverse();

                // Adjust running balance
                let runningBalance = 0;
                for (let i = 0; i < transactionsTemp.length; i += 1) {
                    const trans = transactionsTemp[i];

                    // Initialize balance
                    if (i === 0) {
                        runningBalance = trans.amount;
                        runningBalance = Math.round((runningBalance + Number.EPSILON) * 100) / 100;
                        trans.balance = runningBalance;
                        continue;
                    }

                    if (trans.from._id === this.selectedLoan._id) {
                        trans.amount = -trans.amount;
                        runningBalance = runningBalance - Math.abs(trans.amount);
                        runningBalance = Math.round((runningBalance + Number.EPSILON) * 100) / 100;
                        trans.balance = runningBalance;
                    }

                    if (trans.to._id === this.selectedLoan._id) {
                        runningBalance = runningBalance + trans.amount;
                        runningBalance = Math.round((runningBalance + Number.EPSILON) * 100) / 100;
                        trans.balance = runningBalance;
                    }
                }
                this.transactions = transactionsTemp.reverse();


                // // Prepare balance
                // // let runningBalance = 0;
                // // let runningAmount = 0;
                // for (const trans of this.transactions) {
                //     // if (this.selectedLoan._id) {
                //     //     trans.balance = runningBalance;
                //     // }

                //     if (trans.from._id === this.selectedLoan._id) {
                //         trans.balance = trans.balance_from;
                //     }

                //     if (trans.to._id === this.selectedLoan._id) {
                //         trans.balance = trans.balance_to;
                //     }

                //     // if (this.transactions.indexOf(trans) === 0) {
                //     //     if (trans.from._id === this.selectedLoan._id) {
                //     //         // trans.balance = trans.from_running_balance;
                //     //         trans.balance = trans.from.balance;
                //     //     }
    
                //     //     if (trans.to._id === this.selectedLoan._id) {
                //     //         // trans.balance = trans.to_running_balance;
                //     //         trans.balance = trans.to.balance;
                //     //     }

                //     //     runningBalance = trans.balance;
                //     //     runningAmount = trans.amount;
                //     // } else {
                //     //     // NB: We're working the running balances backwards, so we've filpped the signs


                //     //     if (trans.from._id === this.selectedLoan._id) {
                //     //         // trans.balance = trans.from_running_balance;

                //     //         // runningBalance = runningBalance + trans.amount;
                //     //         runningBalance = runningBalance + runningAmount;
                //     //         // trans.balance = trans.from.balance;
                //     //         // trans.balance = runningBalance;

                //     //     }
    
                //     //     if (trans.to._id === this.selectedLoan._id) {
                //     //         // trans.balance = trans.to_running_balance;

                //     //         // runningBalance = runningBalance - trans.amount;
                //     //         runningBalance = runningBalance - runningAmount;
                //     //         // trans.balance = trans.to.balance;
                //     //         // trans.balance = runningBalance;
                //     //     }
                //     //     trans.balance = runningBalance;
                //     //     runningAmount = trans.amount;
                //     // }
                // }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshLoanCases() {
            try {
                this.loan_cases_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getOperationalExcellenceCases,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'OPERATIONAL_EXCELLENCE',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                loan_number: `${this.selectedLoan.loan_number}`
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.loan_cases_loading = false;
                    return;
                }


                this.loan_cases_loading = false;
                this.loan_cases = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.loan_cases_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
        eventBus.$on('CLOSE_LOAN_SEARCH_DIALOG', this.closeSearchLoansDialog);
        eventBus.$on('CLOSE_ADD_PREMIUM_PAYMENT_DIALOG', this.closeAddPremiumPaymentDialog);
        eventBus.$on('CLOSE_LINK_TRANSACTION_BANK_FEED_DIALOG', this.closeAsociateTransactionWithBankFeedDialog);
        eventBus.$on('CLOSE_VIEW_DEBT_STATUS_DIALOG', this.closeViewDebtStatus);
        eventBus.$on('CLOSE_VIEW_EVENTS_STREAM_DIALOG', this.closeViewEventsStream);
        eventBus.$on('CLOSE_EDIT_LOAN_DIALOG', this.closeEditLoanDialog);
        eventBus.$on('CLOSE_VIEW_LOAN_CASE_DIALOG', this.closeViewCaseDialog);
        eventBus.$on('CLOSE_VIEW_SCHEDULE_DEBIT_ORDER_DETAILS_DIALOG', this.closeViewScheduleDebitOrderDetailsDialog);
        eventBus.$on('CLOSE_REVERSE_LOAN_ALLOCATION_DIALOG', this.closeReverseLoanAllocationDialog);
        eventBus.$on('CLOSE_LOAN_TRANSACTION_ALLOCATION_DIALOG', this.closeLoanTransactionAllocationDialog);
        eventBus.$on('CLOSE_SUBMIT_DEBIT_ORDER_DIALOG', this.closeSubmitDebitOrderDialog);

        // Life cycle
        eventBus.$on('CLOSE_LOAN_PAYOUT_DIALOG', this.closeLoanPayoutDialog);

        // Form reseters
        eventBus.$on('RESET_LOAN_EDIT_FORM', this.resetLoanEditForm);


        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        // try {
        //     this.data_table_loading = true;
        //     const res = await getLoans(this.page, this.limit);
        //     if (res.errors === true) {
        //         emitAlert(res.message);
        //         this.data_table_loading = false;
        //         return;
        //     }

        //     this.data_table_loading = false;
        //     this.loans = res.data;
        // } catch (error) {
        //     this.data_table_loading = false;
        //     console.log(error);
        //     if (error !== undefined) {
        //         emitAlert(error.data.message);
        //     } else {
        //         emitAlert('The server has taken too long in responding to your request');
        //     }
        // }

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getLoans,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'LOAN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            this.data_table_loading = false;
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }

            this.loans = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        'sub_tabs': async function(val) {
            if (val === 4) { // transactions-history
                if (this.selectedLoan !== null) {
                    await this.refreshLoanTransactions();
                }
            }
        }
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>