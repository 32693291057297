<template>
    <div
        style="
            padding:0px;
            width:100%;
            border-top:3px solid #4590a0;
            border-bottom:3px solid #4590a0;
        "
    >
        <v-toolbar
            color="secondary"
            dark
            height="50"
        >
            <v-tooltip
                bottom
                v-if="(
                    collectionObj !== null &&
                    selectedInstallmentSchedule !== null &&
                    selectedInstallmentSchedule.loan.repayment_info.payment_method === 'DEBIT ORDER' &&
                    selectedInstallmentSchedule.softy_comp_collections.length > 0
                )">
                <template v-slot:activator="{ on }">
                    <v-btn
                        style="margin-left: 20px;"
                        color="amber black--text"
                        small
                        @click="openViewScheduleDebitOrderDetailsDialog()"
                        v-on="on"
                    >
                        <v-icon>mdi-cash-100</v-icon> View All Debit Orders
                    </v-btn>
                </template>
                <span>View All Debit Orders</span>
            </v-tooltip>

            <v-tooltip
                bottom
                v-if="(
                    collectionObj !== null &&
                    selectedInstallmentSchedule !== null &&
                    selectedInstallmentSchedule.loan.repayment_info.payment_method === 'DEBIT ORDER' &&
                    (
                        isCentralUnderwriterAdminUser ||
                        isCollectionsManager
                    ) &&
                    selectedInstallmentSchedule.loan.status !== LOAN_STATUSES.REPAID &&
                    selectedInstallmentSchedule.installment_status !== 'FULLY-COLLECTED' &&
                    selectedInstallmentSchedule.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length === 0
                )">
                <template v-slot:activator="{ on }">
                    <v-btn
                        style="margin-left: 20px;"
                        color="success white--text"
                        small
                        @click="openSubmitDebitOrderDialog()"
                        v-on="on"
                    >
                        <v-icon>mdi-cash-100</v-icon> Submit New Debit Order
                    </v-btn>
                </template>
                <span>Submit New Debit Order</span>
            </v-tooltip>
        </v-toolbar>
        <v-data-table
            v-if="collectionObj !== null && collectionObj !== undefined"
            fixed-header
            :height="sub_tab_height"
            :headers="installment_schedule_headers"
            :items="collectionObj.outstanding_loan_schedules.length > 0 ? transformedSchedules() : []"
            item-key="_id"
            class="elevation-1"
            :single-select="true"
            @click:row="selectInstallmentScheduleTabRow"
            item-class="secondary"
            hide-default-footer
            :disable-pagination="true"
        >
            <!-- Loan Number -->
            <template v-slot:item.loan_number="{ item }">
                <td>L{{ item.loan.loan_number }}</td>
            </template>

            <!-- Display Formated deadline_date: false, -->
            <template v-slot:item.deadline_date="{ item }">
                <td v-if="item.deadline_date !== null">{{item.deadline_date.split('T')[0]}}</td>
            </template>
            <!-- Display Formated softy_comp_collections: false, -->
            <!-- <template v-slot:item.loan="{ item }"> -->
            <template v-slot:item.softy_comp_collections="{ item }">
                <td v-if="item.softy_comp_collections !== null">
                    <v-chip
                        v-if="item.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length > 0"
                        color="success"
                        dark
                    >
                        {{ item.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length }}
                    </v-chip> 
                    <v-chip
                        v-else
                        color="red"
                        dark
                    >
                        {{ item.softy_comp_collections.filter(collection => { return collection.local_status === 'ACTIVE'; }).length }}
                    </v-chip> 
                    / 
                    <v-chip
                        color="grey"
                        dark
                    >
                        {{ item.softy_comp_collections.length }}
                    </v-chip>
                </td>
            </template>

            <!-- Age -->
            <template v-slot:item.age_day="{ item }">
                <td>{{ item.age_day }} DAYS</td>
            </template>
            <!-- Premium -->
            <template v-slot:item.loan.premium="{ item }">
                <td>R {{ item.loan.premium }}</td>
            </template>
            <!-- Total collected -->
            <template v-slot:item.collected_amount="{ item }">
                <v-chip
                    color="green"
                    dark
                >
                    R {{ item.collected_amount }}
                </v-chip>
            </template>
            <!-- Total Outstanding -->
            <template v-slot:item.outstanding_amount="{ item }">
                <v-chip
                    color="red"
                    dark
                >
                    R {{ item.outstanding_amount }}
                </v-chip>
            </template>
        </v-data-table>

        <!-- DIALOGS -->
        <ViewScheduleItemDebitOrderDetailsDialog
            v-if="selectedInstallmentSchedule !== null"
            :dialog="open_view_schedule_debit_order_details_dialog"
            :loan="selectedInstallmentSchedule.loan"
            :installment_item="selectedInstallmentSchedule"
        />
        <ResubmitDebitOrderDialog
            v-if="selectedInstallmentSchedule !== null"
            :dialog="open_submit_debit_order_dialog"
            :loan="selectedInstallmentSchedule.loan"
            :installment_schedule_item="selectedInstallmentSchedule"
        />
    </div>
</template>

<script>
import { eventBus } from "../../../../../../main";
import { store } from "../../../../../../../store";
import { LOAN_STATUSES, BUSINESS_PARTNER_TYPES } from "../../../../../../../config";
import ViewScheduleItemDebitOrderDetailsDialog from '../../../../dialogs/view_schedule_debit_order_details.vue';
import ResubmitDebitOrderDialog from '../../../../dialogs/resubmit_debit_order.vue';
export default {
    name: "LoanInstallmentSchedule",
    components: {
        ViewScheduleItemDebitOrderDetailsDialog,
        ResubmitDebitOrderDialog,
    },
    props: {
        collectionObj: {
            required: true,
        },
    },
    data: () => ({
        sub_tab_height:'450',

        installment_schedule_headers: [
            { text: 'Loan Number', value: 'loan_number' },
            { text: 'Installment Number', value: 'installment_number', width: '4%' },
            { text: 'Deadline Date', value: 'deadline_date' },
            // { text: 'Installment Status', value: 'status' },
            { text: 'Active / All [Debit Orders]', value: 'softy_comp_collections' },

            // { text: 'Principal', value: 'premium_principal' },
            // { text: 'Admin Fee', value: 'premium_admin_fee' },
            // { text: 'Init Fee', value: 'premium_initiation_fee' },
            // { text: 'Interest', value: 'premium_interest' },

            { text: 'Age', value: 'age_day' },

            { text: 'Premium', value: 'loan.premium' },
            { text: 'Total Collected', value: 'collected_amount' },
            { text: 'Outstanding', value: 'outstanding_amount' },
        ],

        selectedInstallmentSchedule: null,
        selectedInstallmentScheduleRow: null,

        open_view_schedule_debit_order_details_dialog: false,
        open_submit_debit_order_dialog: false,

        LOAN_STATUSES: LOAN_STATUSES,
    }),
    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isCollectionsManager() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'collections manager'
                                                            );
                                                        }).length > 0;

            return result;
        },
        
    },
    mounted() {
        eventBus.$on('CLOSE_VIEW_SCHEDULE_DEBIT_ORDER_DETAILS_DIALOG', this.closeViewScheduleDebitOrderDetailsDialog);
        eventBus.$on('CLOSE_SUBMIT_DEBIT_ORDER_DIALOG', this.closeSubmitDebitOrderDialog);
    },
    methods: {
        transformedSelectedSchedule() {

        },
        transformedSchedules() {
            const result = this.collectionObj.outstanding_loan_schedules.map(scheduleItem => {
                const matchingSchedule = scheduleItem.loan.installment_schedule.filter(item => {
                    return item.installment_number === scheduleItem.installment_number;
                })[0];
                // console.log('HEY', matchingSchedule)
                if (matchingSchedule !== undefined) {
                    scheduleItem.softy_comp_collections = matchingSchedule.softy_comp_collections;
                    scheduleItem.status = matchingSchedule.status;
                } else {
                    scheduleItem.softy_comp_collections = [];
                    scheduleItem.status = 'NOT-YET-COLLECTED';
                }
                return scheduleItem;
            });
            // console.log(result)
            return result;
        },
        // getScheduleItemSoftyCompCollection(scheduleItem) {
        //     const matchingSchedule = scheduleItem.loan.installment_schedule.filter(item => {
        //         return item.installment_number === scheduleItem.installment_number;
        //     })[0];
        //     if (matchingSchedule !== undefined) {
        //         return matchingSchedule.softy_comp_collections;
        //     }
        //     return null;
        // },
        selectInstallmentScheduleTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedInstallmentSchedule = item;
                this.selectedInstallmentScheduleRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedInstallmentSchedule = null;
                this.selectedInstallmentScheduleRow = null;
            }
        },

        openViewScheduleDebitOrderDetailsDialog() {
            this.open_view_schedule_debit_order_details_dialog = true;
        },
        openSubmitDebitOrderDialog() {
            this.open_submit_debit_order_dialog = true;
        },

        closeViewScheduleDebitOrderDetailsDialog(newLoan, newScheduleItem) {
            if (newLoan !== null && newScheduleItem !== null) {
                this.updateUITableModel(newLoan);
                this.selectedInstallmentSchedule = newScheduleItem;
            }

            this.open_view_schedule_debit_order_details_dialog = false;
        },
        closeSubmitDebitOrderDialog(newLoan, newScheduleItem) {
            if (newLoan !== null && newScheduleItem !== null) {
                this.updateUITableModel(newLoan);
                this.selectedInstallmentSchedule = newScheduleItem;
            }

            this.open_submit_debit_order_dialog = false;
        },

        updateUITableModel(new_loan) {
            if (new_loan !== null) {
                // Emit an event so that the parent can update the reference to the loan object
                eventBus.$emit('LOAN_SERVER_UPDATE', new_loan);


                // const obj_index = this.loans.map(obj => { return obj._id; }).indexOf(new_loan._id)
                // this.loans[obj_index] = new_loan;

                // this.selectedLoan = new_loan;
                // this.selectedLoanCopy = JSON.parse( JSON.stringify(new_loan) );

                //  // Deep copy loans for refreshing main data table
                // const temp_arr = Array.from(this.loans);
                // this.loans = temp_arr;

                // // Also update the documents tab
                // this.loan_documents = new_loan.documents.filter(doc => { return !doc.deleted });
                // this.amortization_schedule = new_loan.pricing_input.amortization_schedule_config;
                // this.installment_schedule = new_loan.installment_schedule;
            }
        },
    },
    watch: {
        collectionObj() {
            // console.log('CHANGED', val);

            if (this.selectedInstallmentScheduleRow !== null) {
                // Unselect currently selected row
                this.selectedInstallmentScheduleRow.select(false);
            }
            this.selectedInstallmentSchedule = null;
            this.selectedInstallmentScheduleRow = null;
        }
    }
};
</script>